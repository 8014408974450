import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { AddAlert, TuneOutlined } from "@mui/icons-material";
// core components
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import CustomCheckbox from "../../../components/CustomCheckBox/CustomCheckBox";
import {
  showAlertNotification,
  showFailureNotification,
  showSuccessNotification,
} from "../../../action-handlers/NotificationsHandler";

//import Button from "../../../components/CustomButtons/Button";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import SAYACustomInput from "../../../components/CustomInput/SAYACustomInput";
import {
  deleteReconUnitData,
  saveReconUnitDetails,
  getDepartmentsById,
  getReconProcessDepartments,
} from "../../../action-handlers/ReconUnitCrudHandlers";
import {
  API_CALL_FAILED,
  API_DELETE_API_WARNING,
  USER_SESSION_TIME_OUT,
} from "../../../messages/DashboardMessages";
import { UserAuthDetailsContext } from "../../../context/LoginPageContext/UserAuthDetailsContext";
import { notificationParams } from "../../../variables/general";
import Snackbar from "../../../components/Snackbar/Snackbar";
import ConfirmationOverlay from "../../../components/ConfirmOverlay/ConfirmationOverlay";
import { fetchUserDetailsByLogin } from "../../../action-handlers/LoginActionsHandler";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";
import { WorkflowContext } from "context/Workflow/WorkflowContext";
import { fetchmappedFlowUnits } from "action-handlers/ReconXActionHandler";
import { reconUnitFormData } from "variables/ReconUnitLevelData";
import paperBg from "../../../assets/img/rainbow-bg.png";

const styles = (theme) => ({
  cardWrapper: {
    borderRadius: "10px",
    backgroundImage: `url(${paperBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    boxShadow: "50px 50px 150px inset #ffffff",
    backgroundColor: "#f6f8ff",

    // background:
    //   theme.palette.type === "dark"
    //     ? "transparent"
    //     : "linear-gradient(180deg, #fff 0%, #fff 100%)",
  },
  formWrapper: {
    border: "5px solid rgb(255 255 255)",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px",
    background:
      theme.palette.type === "dark"
        ? "transparent"
        : "linear-gradient(138.18deg, #eae8fd 0%, #fce5e6 94.44%)",
    borderRadius: "10px",
    "& label": {
      color: "#9c9c9c",
    },
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  inputText: {
    border: "1px solid " + theme.palette.grey[500],
    borderRadius: "6px",
    marginTop: "15px !important",
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    "& input": {
      padding: 10,
    },
    "&:before, &:after": {
      display: "none",
    },
  },
  inputWrapper: {
    "& label": {
      marginLeft: "-14px",
      color: "#dd3c7c !important",
    },
  },
  selectFieldInput: {
    marginTop: "5px",
    marginBottom: "10px",
    "& label": {
      marginLeft: "0px",
      color: "#dd3c7c !important",
    },
  },
  selectInput: {
    marginTop: "-11px",
    "& fieldset": {
      borderColor: "transparent !important",
    },
    "& > label": {
      fontSize: 12,
      color: "#dd3c7c !important",
    },
    "& > div": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
  selectItem: {
    padding: 0,
    backgroundColor:
      theme.palette.type === "dark"
        ? "transparent"
        : "rgb(246 235 250) !important",
    borderBottom:
      theme.palette.type === "dark" ? "1px solid #000" : "1px solid #f2c2d6",
  },
  selectItemText: {
    "& > span": {
      fontSize: 13,
    },
  },
  multimatchWrapper: {
    "& .match-input": {
      backgroundColor:
        theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    },
    "& label": {
      position: "relative",
      top: "20px",
      left: "-14px",
      width: "180px",

      "&+div": {
        marginTop: "10px",
        width: "130px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        paddingLeft: "10px",
      },
    },
  },
  opsCombo: {
    "@media (min-width: 0px) and (max-width: 599.95px)": {
      marginTop: "15px",
    },
    "& label": {
      color: "#dd3c7c !important",
      marginTop: "-7px",
    },
    "& > div": {
      width: "100%",
    },
    "& > div > div": {
      minWidth: "100% !important",
      position: "relative",
      border: "1px solid #919EAB",
      top: "-2px",
      "& > div": {
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
  },
  opsSelect: {
    backgroundColor:
      theme.palette.type === "dark" ? "rgba(145, 158, 171, 0.1)" : "#fff",
    border: "1px solid #919EAB",
  },
  toggleForm: {
    padding: "25px 5px",
    borderRadius: "10px",
    backgroundColor:
      theme.palette.type === "dark" ? "transparent" : "rgb(251 251 251 / 37%)",
    margin: "0px 14px",
    border: "1px dashed #ccc",
  },
  btnWrapper: {
    "& button": {
      boxShadow: "rgb(208 52 125 / 36%) 0px 8px 22px",
      borderRadius: "8px",
    },
  },
  opsHeading: {
    color: "#dd3c7c !important",
    marginTop: "15px",
    fontWeight: "500",
    paddingLeft: "30px",
    borderBottom: "1px dashed #dd3c7338",
    paddingBottom: "10px",
    marginLeft: "-15px",
    marginRight: "-15px",
  },
});

export default function ReconProcessCrud(props) {
  const useStyles = makeStyles(styles);
  const location = useLocation();
  const navigate = useNavigate();
  const [reconProcess, setReconProcess] = useState(location.state);
  const [showConfirmOverlay, setConfirmOverlay] = React.useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [departments, setDepartments] = React.useState([]);
  const [selectedDepts, setSelectedDept] = React.useState([]);
  const [validationMsg, setValidationMsg] = React.useState("");
  const [validationWorkflowMsg, setValidationWorkflowMsg] = React.useState("");
  const UserAuthContext = React.useContext(UserAuthDetailsContext);
  const dsInputRef1 = useRef();
  const dsInputRef2 = useRef();
  const [selectedWorkflows, setSelectedWorkflows] = useState([]);
  const WorkflowDataContext = useContext(WorkflowContext);
  const { workflows } = WorkflowDataContext.state;
  const opsTypes = [
    { label: "ReconX", value: "ReconX" },
    { label: "ResolveX", value: "ResolveX" },
    { label: "ReconX and ResolveX", value: "ReconX and ResolveX" },
  ];

  const formLayoutOptions = [
    {
      value: "1-COLUMN",
      label: "1-COLUMN",
    },
    {
      value: "2-COLUMN",
      label: "2-COLUMN",
    },
    {
      value: "3-COLUMN",
      label: "3-COLUMN",
    },
  ];

  const [selectedOPS, setSelectedOPS] = useState("ReconX");

  let allowedPermissions = [];

  try {
    allowedPermissions =
      location.state === null
        ? JSON.parse(sessionStorage.selectedItem)?.allowedPermissions
        : JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];
  } catch (ex) {
    console.log("Error while reading location.state===>", ex);
  }

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOnSubmit()) {
      const targetDepts = selectedDepts.map(
        (sd) =>
          departments.filter((dept) => dept.departmentName === sd)[0]
            ?.departmentId
      );

      if (selectedOPS === "ReconX") {
        reconProcess.input.reconEnabled = true;
        reconProcess.input.workflowEnabled = false;
      } else if (selectedOPS === "ResolveX") {
        reconProcess.input.reconEnabled = false;
        reconProcess.input.workflowEnabled = true;
      } else {
        reconProcess.input.reconEnabled = true;
        reconProcess.input.workflowEnabled = true;
      }
      const wfProcessList = selectedWorkflows.map((swf) => {
        return workflows.filter((wf) => wf.processName === swf)[0];
      });

      reconProcess.input.wfProcessList = wfProcessList.map((w) => {
        return {
          wfProcessId: w.wfProcessId,
          processType: w.processType,
          processName: w.processName,
        };
      });
      saveReconUnitDetails(
        reconProcess,
        targetDepts,
        UserAuthContext.state,
        callbackSaveReconUnit
      );
    }
  };

  const validateOnSubmit = () => {
    let validateOnSubmit = false;
    const newReconUnitFormData = { ...reconProcess };
    if (!selectedDepts.length) {
      setValidationMsg("Please select at lease 1 Department");
      return false;
    }
    if (reconProcess.input.dataSrcConfigId1.trim() === "") {
      newReconUnitFormData.validationErrs.dataSrcConfigId1 =
        "Please enter Data Source1 Config name";
      setReconProcess(newReconUnitFormData);
      validateOnSubmit = false;
      return validateOnSubmit;
    }
    if (reconProcess.input.dataSrcConfigId2.trim() === "") {
      newReconUnitFormData.validationErrs.dataSrcConfigId2 =
        "Please enter Data Source2 Config name";
      setReconProcess(newReconUnitFormData);
      validateOnSubmit = false;
      return validateOnSubmit;
    }
    if (
      reconProcess.input.clientReconName === undefined ||
      reconProcess.input.clientReconName === ""
    ) {
      newReconUnitFormData.validationErrs.clientReconName =
        "Please enter the Ops Process name";
      setReconProcess(newReconUnitFormData);
      validateOnSubmit = false;
    } else {
      validateOnSubmit = true;
    }

    if (selectedOPS !== "ReconX") {
      if (selectedWorkflows.length === 0) {
        newReconUnitFormData.validationErrs.workflowEnabled =
          "Please Select atlease 1 Workflow";
        setReconProcess(newReconUnitFormData);
        setValidationWorkflowMsg("Please Select atlease 1 Workflow");
        validateOnSubmit = false;
      }
    }
    return validateOnSubmit;
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleChange = (e) => {
    let maxLength = 30;
    const newReconUnitFormData = { ...reconProcess };
    if (e.target.value.length <= maxLength) {
      newReconUnitFormData.input.clientReconName = e.target.value;
      newReconUnitFormData.validationErrs.clientReconName = ""; //resetting the errors
    } else {
      /* as of now maxLength prop is not working hence written this custome validtion*/
      // newReconUnitFormData.validationErrs.clientReconName =
      //   "Ops Process Name limit exceeded!!!";
    }
    setReconProcess(newReconUnitFormData);
  };

  const handleDSConfigChange = (e) => {
    const newReconUnitFormData = { ...reconProcess };
    newReconUnitFormData.input[e.target.name] = e.target.value;
    newReconUnitFormData.validationErrs[e.target.name] = "";
    setReconProcess(newReconUnitFormData);
  };

  const handleBufferChange = (e) => {
    const newReconUnitFormData = { ...reconProcess };
    newReconUnitFormData.input.multimatchAggrBuffer = e.target.value.replace(
      /[^0-9]/g,
      ""
    );

    newReconUnitFormData.validationErrs.multimatchAggrBuffer = ""; //resetting the errors

    setReconProcess(newReconUnitFormData);
  };

  const handleDelete = () => {
    let { reconUnitId } = reconProcess.input;
    let { userId } = UserAuthContext.state; // Added new param for delete functionality
    if (
      reconUnitId !== "" &&
      typeof reconUnitId !== "undefined" &&
      typeof userId !== "undefined" &&
      userId !== 0
    ) {
      deleteReconUnitData(reconUnitId, userId, callbackDeleteReconUnit);
    } else {
      showAlertNotification(API_DELETE_API_WARNING, callbackNotification);
    }
  };

  const callbackDeleteReconUnit = (isSuccess) => {
    if (isSuccess) {
      closeOverlay();
      navigate("/saya-platform/admin/ops-home");
    } else {
      showFailureNotification(API_CALL_FAILED, callbackNotification);
    }
  };

  const callbackSaveReconUnit = (isSuccess, msg = "") => {
    if (isSuccess) {
      showSuccessNotification(msg, callbackNotification);
      setTimeout(() => {
        navigate("/saya-platform/admin/ops-home");
      }, 1000);
    } else {
      showFailureNotification(msg || API_CALL_FAILED, callbackNotification);
    }
  };

  const handleToggle = (value, componentId) => {
    const newReconUnitFormData = { ...reconProcess };
    if (componentId === "identicalData") {
      newReconUnitFormData.input.identicalData =
        !newReconUnitFormData.input.identicalData;
      newReconUnitFormData.validationErrs.identicalData = ""; //resetting the errors
    } else if (componentId === "headerData") {
      newReconUnitFormData.input.headerData =
        !newReconUnitFormData.input.headerData;
      newReconUnitFormData.validationErrs.headerData = ""; //resetting the errors
    } else if (componentId === "multimatchReqd") {
      newReconUnitFormData.input.multimatchReqd =
        !newReconUnitFormData.input.multimatchReqd;
      newReconUnitFormData.validationErrs.multimatchReqd = ""; //resetting the errors
      if (newReconUnitFormData.input.multimatchReqd) {
        newReconUnitFormData.input.oneToMany = true;
        newReconUnitFormData.input.manyToOne = true;
      } else {
        newReconUnitFormData.input.oneToMany = false;
        newReconUnitFormData.input.manyToOne = false;
      }
    } else if (componentId === "oneToMany") {
      if (
        newReconUnitFormData.input.oneToMany &&
        !newReconUnitFormData.input.manyToOne
      ) {
        newReconUnitFormData.input.oneToMany =
          !newReconUnitFormData.input.oneToMany;
        newReconUnitFormData.validationErrs.oneToMany = ""; //resetting the errors
        newReconUnitFormData.input.manyToOne =
          !newReconUnitFormData.input.oneToMany;
        newReconUnitFormData.validationErrs.manyToOne = ""; //resetting the errors
      } else {
        newReconUnitFormData.input.oneToMany =
          !newReconUnitFormData.input.oneToMany;
        newReconUnitFormData.validationErrs.oneToMany = ""; //resetting the errors
      }
    } else if (componentId === "manyToOne") {
      if (
        !newReconUnitFormData.input.oneToMany &&
        newReconUnitFormData.input.manyToOne
      ) {
        newReconUnitFormData.input.manyToOne =
          !newReconUnitFormData.input.manyToOne;
        newReconUnitFormData.validationErrs.manyToOne = ""; //resetting the errors
        newReconUnitFormData.input.oneToMany =
          !newReconUnitFormData.input.manyToOne;
        newReconUnitFormData.validationErrs.oneToMany = ""; //resetting the errors
      } else {
        newReconUnitFormData.input.manyToOne =
          !newReconUnitFormData.input.manyToOne;
        newReconUnitFormData.validationErrs.manyToOne = ""; //resetting the errors
      }
    } else if (componentId === "mlEnabled") {
      newReconUnitFormData.input.mlEnabled =
        !newReconUnitFormData.input.mlEnabled;
      newReconUnitFormData.validationErrs.mlEnabled = ""; //resetting the errors
    }
    setReconProcess(newReconUnitFormData);
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
    } else {
      //failure could be due to token expiry, raise an alert saying Token Expired and route to login page
      showAlertNotification(USER_SESSION_TIME_OUT, callbackNotification);
      UserAuthContext.clearUserDetailsAuthState();
      navigate("/");
    }
  };

  const fetchFlowUnits = async (reconUnitId) => {
    if (reconUnitId) {
      fetchmappedFlowUnits(reconUnitId, (res) => {
        if (res?.length) {
          setSelectedWorkflows(res.map((r) => r.flowDefnName));
        }
      });
    }
  };

  const resetData = () => {
    setReconProcess(JSON.parse(JSON.stringify(reconUnitFormData)));
  };

  useEffect(() => {
    const data = location.state?.input;
    if (location.state.new) {
      resetData();
      setSelectedOPS("ReconX");
    } else if (data?.reconEnabled && data?.workflowEnabled) {
      setSelectedOPS("ReconX and ResolveX");
      fetchFlowUnits(data?.reconUnitId);
    } else if (data?.workflowEnabled) {
      setSelectedOPS("ResolveX");
      fetchFlowUnits(data?.reconUnitId);
    } else {
      setSelectedOPS("ReconX");
    }
  }, [location.state]);

  //used to get all departments
  useEffect(() => {
    if (UserAuthContext.state.userId !== 0) {
      getDepartmentsById(UserAuthContext.state?.userId, (isSuccess, res) => {
        setDepartments(res);
        setSelectedDept(res.map((dept) => dept.departmentName));
      });
    } else {
      fetchUserDetailsByLogin(
        UserAuthContext.state.loginName,
        callBackFetchUserDetailsByLogin
      );
    }
  }, [UserAuthContext.state.userId]);

  //used to get selected departments by reconId
  useEffect(() => {
    if (reconProcess?.input?.reconUnitId !== "" && departments.length) {
      getReconProcessDepartments(
        reconProcess?.input?.reconUnitId,
        (status, data) => {
          const targetDepts = data.map(
            (sd) =>
              departments.filter(
                (dept) => dept.departmentId === sd.departmentId
              )[0]?.departmentName
          );
          setSelectedDept(targetDepts.filter((dept) => dept));
        }
      );
    }
  }, [reconProcess?.input?.reconUnitId, departments]);

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const toggleDept = (event) => {
    const {
      target: { value },
    } = event;
    if (validationMsg.length) {
      setValidationMsg("");
    }
    setSelectedDept(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    const handleFocus = (event) => {
      event.target.select();
    };

    // Attach the focus event listener when the component mounts
    if (dsInputRef1.current) {
      dsInputRef1.current.addEventListener("focus", handleFocus);
    }
    if (dsInputRef2.current) {
      dsInputRef2.current.addEventListener("focus", handleFocus);
    }

    // Detach the focus event listener when the component unmounts
    return () => {
      if (dsInputRef1.current) {
        dsInputRef1.current.removeEventListener("focus", handleFocus);
      }
      if (dsInputRef2.current) {
        dsInputRef2.current.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  const handleOPS = (e) => {
    setSelectedOPS(e.target.value);
  };

  const toggleWorkflow = (event) => {
    const {
      target: { value },
    } = event;
    if (validationWorkflowMsg.length) {
      setValidationWorkflowMsg("");
    }
    setSelectedWorkflows(typeof value === "string" ? value.split(",") : value);
  };

  return (
    reconProcess && (
      <div>
        <form onSubmit={handleSubmit} noValidate xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Box className={classes.cardWrapper}>
                <Card plain>
                  <CardHeader color="primary">
                    {/* <h4 style={{ margin: "5px 0" }}>Ops Process Setup</h4>
                  <p>Please input the details and click save or delete.</p> */}
                  </CardHeader>

                  <CardBody style={{ position: "relative" }}>
                    <Box
                      display="flex"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <GridItem xs={12} sm={12} md={8} lg={6}>
                        <Box
                          // display="flex"
                          // justifyContent="space-evenly"
                          // alignItems="center"
                          // className={classes.formBg}
                          className={classes.formWrapper}
                          style={{
                            padding: "20px 10px 20px 10px",
                            marginBottom: 20,
                          }}
                        >
                          {/* <h5 className={classes.opsHeading}>Ops Process Setup</h5> */}
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <div className={classes.inputWrapper}>
                                <SAYACustomInput
                                  state={reconProcess}
                                  handleChange={handleChange}
                                  labelText="Ops Process Name"
                                  id="clientReconName"
                                  position="center"
                                  inputProps={{
                                    style: { textAlign: "center" },
                                    maxLength: "10",
                                    className: classes.inputText,
                                    autocomplete: "off",
                                  }}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  margin="normal"
                                />
                                <span
                                  style={{ color: "red" }}
                                  className="validation-errors"
                                >
                                  {reconProcess.validationErrs.clientReconName}
                                </span>
                              </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} sx={{ mb: "0px" }}>
                              <div className={classes.opsCombo}>
                                <SAYAComboBox
                                  optionsData={opsTypes}
                                  value={selectedOPS}
                                  defaultValue={selectedOPS}
                                  labelText="Select OPS Process Type"
                                  groupText="Selected  OPS Process Type"
                                  name="selectedReconUnit"
                                  handleChange={handleOPS}
                                  className="SelectSS"
                                  height="30px"
                                  headerCombo
                                />
                              </div>
                            </GridItem>
                          </GridContainer>
                          <Box className={classes.toggleForm}>
                            <GridContainer style={{ marginBottom: "15px" }}>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  size="small"
                                  style={{ width: "100%" }}
                                  className={classes.selectInput}
                                  sx={{
                                    paddingTop: {
                                      xs: "20px",
                                      md: "0",
                                      marginBottom: "10px",
                                    },
                                  }}
                                >
                                  {/* <InputLabel id="demo-multiple-checkbox-label">
                                Department
                              </InputLabel> */}
                                  <label
                                    style={{
                                      color: "#637381",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Select Department
                                  </label>
                                  <Select
                                    style={{ marginTop: 7 }}
                                    labelId="dept-multiple-checkbox-label"
                                    id="dept-multiple-checkbox"
                                    multiple
                                    value={selectedDepts}
                                    onChange={toggleDept}
                                    // input={<OutlinedInput label="Department" />}
                                    renderValue={(selected) =>
                                      selected.join(", ")
                                    }
                                    MenuProps={MenuProps}
                                    className={classes.opsSelect}
                                  >
                                    {departments.map((dept) => (
                                      <MenuItem
                                        key={dept.departmentId}
                                        value={dept.departmentName}
                                        className={classes.selectItem}
                                      >
                                        <Checkbox
                                          checked={
                                            selectedDepts.indexOf(
                                              dept.departmentName
                                            ) > -1
                                          }
                                        />
                                        <ListItemText
                                          primary={dept.departmentName}
                                          className={classes.selectItemText}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <div
                                  style={{ color: "red" }}
                                  className="validation-errors"
                                >
                                  {validationMsg}
                                </div>
                              </GridItem>

                              {selectedOPS !== "ReconX" && (
                                <GridItem xs={12} sm={12} md={6}>
                                  <FormControl
                                    size="small"
                                    style={{ width: "100%" }}
                                    className={classes.selectInput}
                                    sx={{
                                      paddingTop: { xs: "20px", md: "0" },
                                    }}
                                  >
                                    <label
                                      style={{
                                        color: "#637381",
                                        fontSize: "11px",
                                      }}
                                    >
                                      Applicable Workflows
                                    </label>
                                    <Select
                                      style={{ marginTop: 7 }}
                                      labelId="dept-multiple-checkbox-label"
                                      id="dept-multiple-checkbox"
                                      multiple
                                      value={selectedWorkflows}
                                      onChange={toggleWorkflow}
                                      // input={<OutlinedInput label="Department" />}
                                      renderValue={(selected) =>
                                        selected.join(", ")
                                      }
                                      MenuProps={MenuProps}
                                      className={classes.opsSelect}
                                    >
                                      {workflows.map((wf) => (
                                        <MenuItem
                                          key={wf.wfProcessId}
                                          value={wf.processName}
                                          className={classes.selectItem}
                                        >
                                          <Checkbox
                                            checked={
                                              selectedWorkflows.indexOf(
                                                wf.processName
                                              ) > -1
                                            }
                                          />
                                          <ListItemText
                                            primary={wf.processName}
                                            className={classes.selectItemText}
                                          />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <div
                                    style={{ color: "red" }}
                                    className="validation-errors"
                                  >
                                    {validationWorkflowMsg}
                                  </div>
                                </GridItem>
                              )}
                            </GridContainer>

                            <GridContainer>
                              <GridItem
                                xs={12}
                                sm={6}
                                md={6}
                                //style={{ marginTop: 15 }}
                              >
                                <div className={classes.inputWrapper}>
                                  <SAYACustomInput
                                    state={reconProcess}
                                    handleChange={handleDSConfigChange}
                                    labelText="Data source Config 1"
                                    id="dataSrcConfigId1"
                                    position="center"
                                    inputProps={{
                                      style: { textAlign: "center" },
                                      maxLength: "10",
                                      className: classes.inputText,
                                      name: "dataSrcConfigId1",
                                      inputRef: dsInputRef1,
                                    }}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    margin="normal"
                                  />
                                  <span
                                    style={{ color: "red" }}
                                    className="validation-errors"
                                  >
                                    {
                                      reconProcess.validationErrs
                                        .dataSrcConfigId1
                                    }
                                  </span>
                                </div>
                              </GridItem>
                              {selectedOPS !== "ResolveX" && (
                                <GridItem
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  //style={{ marginTop: 15 }}
                                >
                                  <div className={classes.inputWrapper}>
                                    <SAYACustomInput
                                      state={reconProcess}
                                      handleChange={handleDSConfigChange}
                                      labelText="Data source Config 2"
                                      id="dataSrcConfigId2"
                                      position="center"
                                      inputProps={{
                                        style: { textAlign: "center" },
                                        maxLength: "10",
                                        className: classes.inputText,
                                        name: "dataSrcConfigId2",
                                        inputRef: dsInputRef2,
                                      }}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      margin="normal"
                                    />
                                    <span
                                      style={{ color: "red" }}
                                      className="validation-errors"
                                    >
                                      {
                                        reconProcess.validationErrs
                                          .dataSrcConfigId2
                                      }
                                    </span>
                                  </div>
                                </GridItem>
                              )}
                            </GridContainer>

                            {/* <GridContainer>
                              {selectedOPS !== "ReconX" && (
                                <GridItem xs={12} sm={6} md={6}>
                                  <div className={classes.selectFieldInput}>
                                  <SAYAComboBox
                                    optionsData={formLayoutOptions}
                                    value={reconProcess.input["formLayout"]}
                                    labelText="Form Layout"
                                    groupText="Form Layout"
                                    handleChange={handleDSConfigChange}
                                    className="Select"
                                    height="20px"
                                    headerCombo
                                    inputProps={{
                                      name: "formLayout",
                                    }}
                                  
                                  />
                                  </div>
                                </GridItem>
                              )}
                            </GridContainer> */}

                            {selectedOPS !== "ResolveX" && (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  <CustomCheckbox
                                    value={reconProcess.input["multimatchReqd"]}
                                    id="multimatchReqd"
                                    handleToggle={() =>
                                      handleToggle(
                                        reconProcess.input["multimatchReqd"],
                                        "multimatchReqd"
                                      )
                                    }
                                    labelText="Enable Multi-match"
                                  />
                                </GridItem>
                              </GridContainer>
                            )}

                            {selectedOPS !== "ResolveX" &&
                            reconProcess.input["multimatchReqd"] ? (
                              <>
                                <GridContainer>
                                  <GridItem xs={12} sm={2} md={1}></GridItem>
                                  <GridItem xs={12} sm={4} md={4}>
                                    <CustomCheckbox
                                      value={reconProcess.input["oneToMany"]}
                                      id="oneToMany"
                                      handleToggle={() =>
                                        handleToggle(
                                          reconProcess.input["oneToMany"],
                                          "oneToMany"
                                        )
                                      }
                                      labelText="One to Many"
                                    />
                                  </GridItem>
                                  <GridItem xs={12} sm={6} md={6}>
                                    <CustomCheckbox
                                      value={reconProcess.input["manyToOne"]}
                                      id="manyToOne"
                                      handleToggle={() =>
                                        handleToggle(
                                          reconProcess.input["manyToOne"],
                                          "manyToOne"
                                        )
                                      }
                                      labelText="Many to One"
                                    />
                                  </GridItem>
                                </GridContainer>
                                <div className={classes.multimatchWrapper}>
                                  <GridContainer>
                                    <GridItem xs={12} sm={2} md={1}></GridItem>
                                    <GridItem xs={12} sm={10} md={4}>
                                      <SAYACustomInput
                                        state={reconProcess}
                                        handleChange={handleBufferChange}
                                        labelText="Multi-match buffer value"
                                        id="multimatchAggrBuffer"
                                        position="center"
                                        inputProps={{
                                          style: { textAlign: "center" },
                                          type: "number",
                                          /*max: "1000",*/
                                          min: "0",
                                          className: "match-input",
                                        }}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        margin="normal"
                                      />
                                      <span
                                        style={{ color: "red" }}
                                        className="validation-errors"
                                      >
                                        {
                                          reconProcess.validationErrs
                                            .clientReconName
                                        }
                                      </span>
                                    </GridItem>
                                  </GridContainer>
                                </div>
                              </>
                            ) : null}

                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <CustomCheckbox
                                  value={reconProcess.input["mlEnabled"]}
                                  id="mlEnabled"
                                  handleToggle={() =>
                                    handleToggle(
                                      reconProcess.input["mlEnabled"],
                                      "mlEnabled"
                                    )
                                  }
                                  labelText="Machine Learning"
                                />
                              </GridItem>

                              {selectedOPS !== "ResolveX" && (
                                <GridItem xs={12} sm={6} md={6}>
                                  <CustomCheckbox
                                    value={reconProcess.input["identicalData"]}
                                    id="identicalData"
                                    handleToggle={() =>
                                      handleToggle(
                                        reconProcess.input["identicalData"],
                                        "identicalData"
                                      )
                                    }
                                    labelText="Identical Metadata"
                                  />
                                </GridItem>
                              )}
                            </GridContainer>

                            <div className={classes.btnWrapper}>
                              <br />
                              <Grid
                                container
                                justifyContent={"center"}
                                spacing={0}
                                mb={3}
                              >
                                {(!location.state.new &&
                                  allowedPermissions.includes("modify")) ||
                                (location.state.new &&
                                  allowedPermissions.includes("add")) ? (
                                  <GridItem>
                                    <Button
                                      style={{ marginLeft: "-15px" }}
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                      sx={{ xs: { padding: "10px 25px" } }}
                                    >
                                      SAVE
                                    </Button>
                                  </GridItem>
                                ) : null}
                                {!location.state.new &&
                                  allowedPermissions.includes("delete") && (
                                    <GridItem>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => openOverlay(true)}
                                        sx={{ xs: { padding: "10px 25px" } }}
                                      >
                                        DELETE
                                      </Button>
                                    </GridItem>
                                  )}
                              </Grid>
                            </div>
                          </Box>
                        </Box>
                      </GridItem>
                    </Box>
                    {/* <TuneOutlined
                    sx={{
                      display: { xl: "block", xs: "none" },
                      fontSize: 260,
                      position: "absolute",
                      top: "0",
                      right: "0",
                      bottom: 15,
                      opacity: 0.1,
                    }}
                  /> */}
                  </CardBody>
                </Card>
              </Box>
            </GridItem>
          </GridContainer>
        </form>
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={AddAlert}
          message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
        />
        {showConfirmOverlay && (
          <ConfirmationOverlay
            {...{
              isOverlayOpen: showConfirmOverlay,
              closeOverlay,
              handler: handleDelete,
              title: "DELETE",
              message: `Are you sure you want to delete? Once you delete you will loss all the data associated to the record.`,
            }}
          />
        )}
      </div>
    )
  );
}
