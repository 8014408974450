import { API, USERAPI } from "URL.js";
import axios from "axios";

export const deleteReconUnitData = (reconUnitId, userId, callback) => {
  let isSuccess = false;
  axios
    .post(API + "/reconunits/close/" + reconUnitId + "/" + userId)
    .then((response) => {
      if (response.status === 200) {
        isSuccess = true;
        callback(isSuccess);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(isSuccess);
    });
};

export const fetchOPSProcesses = (userId, callback) => {
  axios
    .get(API + "/reconunits/byuser/" + userId)
    .then((response) => {
      callback(response.data, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback([], false);
    });
};

export const saveReconUnitDetails = (
  reconProcess,
  selectedDepts,
  contextState,
  callback
) => {
  let payload = {};
  let data = {
    ...reconProcess.input,
    userId: contextState?.userId,
    createdBy: contextState?.userId,
    parentOrgId: contextState?.clientOrgId,
    formLayout: reconProcess.input.formLayout || "1-COLUMN",
  };
  payload.clientReconUnit = data;
  payload.departmentIdList = selectedDepts;
  payload.wfProcessList = reconProcess.input.wfProcessList;
  let isSuccess = false;

  axios
    .post(API + "/reconunits/create", payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        isSuccess = true;
        callback(isSuccess, response.data?.responseMessage);
      } else {
        isSuccess = false;
        callback(isSuccess, response.data?.responseMessage);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(false);
    });
};

export const getDepartmentsById = (userId, callback) => {
  axios
    .get(`${USERAPI}/department/all/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((error) => {
      console.log("Error inside getDepartmentsById function", error);
      callback(false, []);
    });
};

export const getReconProcessDepartments = (reconUnitId, callback) => {
  axios
    .get(`${API}/reconunits/reconunitdepartments/${reconUnitId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((error) => {
      console.log("Error inside getReconProcessDepartments function", error);
      callback(false, []);
    });
};
