import React, { useState, useMemo, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export default function Component({
  chartData,
  label,
  value,
  datasetLabel,
  title,
  sortKey,
  selectedSorting: initialSorting,
}) {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedSorting, setSelectedSorting] = useState(initialSorting)
  const itemsPerPage = 5

  const sortedData = useMemo(() => {
    if (!chartData) return []
    return [...chartData].sort((a, b) => {
      if (selectedSorting === "Ascending") {
        return a[sortKey] - b[sortKey]
      } else {
        return b[sortKey] - a[sortKey]
      }
    })
  }, [chartData, sortKey, selectedSorting])

  const paginatedData = useMemo(() => {
    const start = currentPage * itemsPerPage
    return sortedData.slice(start, start + itemsPerPage)
  }, [sortedData, currentPage])

  const generateColors = useCallback((count) => {
    const baseColors = [
      "rgb(255, 99, 132)",
      "rgb(54, 162, 235)",
      "rgb(255, 206, 86)",
      "rgb(75, 192, 192)",
      "rgb(153, 102, 255)",
      "rgb(255, 159, 64)",
      "rgb(231, 233, 237)",
    ]

    return Array.from({ length: count }, (_, i) => {
      const color = baseColors[i % baseColors.length]
      return color.replace("rgb", "rgba").replace(")", ", 0.5)")
    })
  }, [])

  const backgroundColors = useMemo(() => generateColors(chartData.length), [chartData.length, generateColors])

  const data = {
    labels: paginatedData.map((item) => item[label]),
    datasets: [
      {
        label: datasetLabel,
        data: paginatedData.map((item) => item[value]),
        backgroundColor: paginatedData.map((item) => backgroundColors[chartData.indexOf(item)]),
        borderColor: paginatedData.map((item) => backgroundColors[chartData.indexOf(item)].replace("0.5", "1")),
        borderWidth: 1,
        borderRadius: 6,
      },
    ],
  }

  const options = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ""
            if (label) {
              label += ": "
            }
            if (context.parsed.x !== null) {
              label += context.parsed.x.toFixed(2) + "%"
            }
            return label
          },
        },
        titleFont: {
          family: "Poppins",
        },
        bodyFont: {
          family: "Poppins",
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"
          },
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    barThickness: 25,
    maxBarThickness: 50,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const barIndex = elements[0].index
        const reconProcessId = paginatedData[barIndex]?.reconProcessId
        navigate(`/saya-platform/saya-reconx?id=${reconProcessId}`)
      }
    },
  }

  const totalPages = Math.ceil(sortedData.length / itemsPerPage)

  const handleSort = (sorting) => {
    setSelectedSorting(sorting)
    setCurrentPage(0) // Reset to first page when sorting changes
  }

  if (chartData.length === 0) {
    return (
      <div className="bg-white w-full h-[400px] flex items-center justify-center">
        <p className="text-gray-500 text-sm">No data available for the selected criteria.</p>
      </div>
    )
  }

  return (
    <div className="bg-white w-full h-[420px] rounded-lg shadow-md">
      <div className="flex flex-row items-center justify-between space-y-0 p-3 border-b">
        <h2 className="leading-4 sm:leading-normal font-medium tracking-tight text-gray-800 text-sm text-left">{title}</h2>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => handleSort("Ascending")}
            className={`px-2 py-1 text-xs font-medium rounded-md border ${
              selectedSorting === "Ascending"
                ? "text-pink-600 border-pink-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline-block mr-0 sm:mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden sm:inline">Asc</span>
          </button>
          <button
            onClick={() => handleSort("Descending")}
            className={`px-2 py-1 text-xs font-medium rounded-md border ${
              selectedSorting === "Descending"
                ? "text-pink-600 border-pink-600"
                : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline-block mr-0 sm:mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden sm:inline">Desc</span>
          </button>
          <button
            className="p-1 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <span className="text-sm text-gray-600">
            Page {currentPage + 1} of {totalPages}
          </span>
          <button
            className="p-1 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1))}
            disabled={currentPage === totalPages - 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
      <div className="h-[calc(100%-4rem)] p-2 md:p-6 pb-8">
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}