import React from "react";
import NumberTicker from "../../ui/magic-ui/NumberTicker";

const Tile = ({ item, click }) => {
  const { title = "", count = 0, isActive, Icon, type, isDisabled } = item;


  return (
    <div
      className={`
      ${
        isDisabled ? "pointer-events-none opacity-70" : ""
      } w-full rounded-lg border shadow-sm cursor-pointer transition-all duration-300 transform hover:scale-105
      ${
        isActive
          ? "bg-white border-pink-500 text-gray-800 bg-gradient-to-t from-pink-50 to-white via-white via-55%"
          : "bg-white hover:bg-gray-50 text-gray-800"
      }
    `}
      onClick={() => click(item)}
    >
      <div className="p-4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-base font-medium mt-0">{type}</h3>
          {Icon && (
            <div className={isActive ? "text-pink-500" : "text-pink-500"}>
              <Icon size={30} />
            </div>
          )}
        </div>
        <div>
          <div className="text-3xl font-bold">
            {count.toLocaleString() > 0 ? (
              <NumberTicker value={count.toLocaleString()} delay={0} />
            ) : (
              <span className="text-gray-500">0</span>
            )}
          </div>
          <p
            className={`text-xs ${
              isActive ? "text-gray-500" : "text-gray-500"
            }`}
          >
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Tile);
