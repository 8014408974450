'use client'

import React from "react"
import { ArrowRight, Bookmark, ChartArea } from "lucide-react"

const colorClasses = [
  //"bg-green-50",
  // "bg-green-50",
  // "bg-purple-50",
  // "bg-pink-50",
  // "bg-yellow-50",
  // "bg-indigo-50",
]

function AnalytixTile({ data = [], redirectToAnalytixDashboard }) {
  return (
    <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 bg-white px-8 py-10 rounded-lg">
    <div class="absolute inset-0 rounded-lg z-0 h-full w-full bg-white bg-[radial-gradient(#e4c6ff_1px,transparent_1px)] [background-size:16px_16px]"></div>
      {data.map((ad, index) => (
        <div
          key={index}
          className={`${
            colorClasses[index % colorClasses.length]
          } rounded-xl shadow-lg  hover:shadow border duration-300 overflow-hidden bg-[#fff] relative z-[1]`}
        >
          <div className="p-4 relative">
            <div className="flex justify-between items-start mb-4">
              <p className="text-sm font-medium text-gray-500">AnalytiX ID: {ad.analytixId}</p>
            </div>
            <h3 className="text-base font-medium mb-1 text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">{ad.analytixName}</h3>

          </div>
          <div className="flex items-center justify-between p-2 bg-white border-t">
            <div className="flex items-center space-x-2">
              <div className="bg-pink-100 p-2 rounded-full">
                <ChartArea className="w-5 h-5 text-pink-600" />
              </div>
              <span className="text-sm font-medium text-gray-600">Analytics Dashboard</span>
            </div>
            <button className="bg-gray-900 text-white px-4 py-1 rounded-full text-sm font-medium hover:bg-gray-800 transition-colors duration-300" onClick={() => redirectToAnalytixDashboard(ad.embeddedId)}>
              View
            </button>
          </div>

        </div>
      ))}
    </div>
  )
}

export default React.memo(AnalytixTile)