/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { Settings } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import Menu from "@mui/material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Popover from "@mui/material/Popover";
import Divider from "@mui/material/Divider";

// core components
import FixedPlugin from "../FixedPlugin/FixedPlugin";
import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";

import useResponsive from "../../hooks/useResponsive";

import LogoImage from "../../assets/img/favicon.png";
import Reconcile from "../../assets/img/reconcile.svg";
const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sideNanVisible, setSideNanVisible] = React.useState(true);

  const isDesktop = useResponsive("up", "lg");

  //   <img
  //   src={LogoImage}
  //   alt="SAYA"
  //   className={classes.imgSmall}
  //   style={{ width: 10}}
  // />
  /* //Commented below functions as we are not using below functionality for now
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };*/

  React.useEffect(() => {
    if (props.location?.state && props.location?.state?.firstLogin) {
      setSideNanVisible(false);
    } else {
      setSideNanVisible(true);
    }
  }, []);

  const handleSettingsMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setAnchorEl(null);
  };

  const settingsMenuOpen = Boolean(anchorEl);
  const settingsMenuId = settingsMenuOpen ? "simple-popover" : undefined;

  const sideNavClickHandler = () => {
    if (props.open) {
      props.handleDrawerToggle();
    } else {
      return false;
    }
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  function activeClass(routeName) {
    if (
      routeName.includes("admin") &&
      window.location.href.includes("/admin")
    ) {
      return true;
    }
    return false;
  }

  const {
    color,
    logo,
    logoSmall,
    image,
    logoText,
    routes,
    logoContent,
    favicon,
    faviconimgTyp,
    logoimgType,
  } = props;
  var links = (
    <List
      className={classNames(classes.list, !props.open && classes.collpaseNav)}
    >
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;

        if (prop.invisible || !prop?.allowedPermissions.includes("view"))
          return null;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });

        const activeMenuClass = classNames({
          [" " + "active"]: activeClass(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={activePro + classes.item + activeMenuClass}
            activeClassName="active"
            key={key}
          >
            {/* Added Tooltip to show menu text when side menu collapsed */}
            <Tooltip
              title={
                !props.open ? (props.rtlActive ? prop.rtlName : prop.name) : ""
              }
              placement="bottom-start"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [50, -50],
                    },
                  },
                ],
              }}
            >
              <ListItem
                style={{ display: "flex" }}
                button
                className={classes.itemLink + listItemClasses}
                onClick={sideNavClickHandler}
              >
                {prop.name.toLowerCase() === "forImage" ? (
                  <img
                    alt=""
                    style={{
                      width: "32px",
                      marginRight: "12px",
                      marginLeft: "-3px",
                      position: "relative",
                      top: "4px",
                    }}
                    src={Reconcile}
                  />
                ) : typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive,
                    })}
                  />
                )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </Tooltip>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="#SAYA#"
        onClick={(e) => e.preventDefault()}
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          {props.open && (
            <img
              style={{ width: 184, height: 58 }}
              src={
                logoContent ? `data:${logoimgType};base64,${logoContent}` : logo
              }
              alt="SAYA"
              className={classes.img}
            />
          )}
          {!props.open && (
            <img
              src={
                favicon ? `data:${faviconimgTyp};base64,${favicon}` : logoSmall
              }
              alt="SAYA"
              className={classes.imgSmall}
              style={{ width: 30, height: 30 }}
              onClick={props.handleDrawerToggle}
            />
            // <IconButton
            //   color="inherit"
            //   aria-label="open drawer"
            //   edge="start"
            //   className={classes.hamburgerIcon}
            //   onClick={props.handleDrawerToggle}
            // >
            //   <Menu />
            // </IconButton>
          )}
        </div>
        {logoText}
        {props.open && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            className={classes.leftArrowIcon}
            onClick={props.handleDrawerToggle}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
      </a>
    </div>
  );

  let settingsComp = (
    <div className={classes.settingsWrapper}>
      <List
        component="nav"
        aria-label="secondary mailbox folders"
        onClick={handleSettingsMenuClick}
      >
        <ListItem button className={classes.settingsIcon}>
          <Settings className={classes.itemIcon} />{" "}
          {props.open && (
            <ListItemText
              primary="Settings"
              className={classes.itemText}
              disableTypography={true}
            />
          )}
        </ListItem>
      </List>

      <Popover
        className={classes.settingsMenu}
        id={settingsMenuId}
        open={settingsMenuOpen}
        anchorEl={anchorEl}
        onClose={handleSettingsMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
        <FixedPlugin />
      </Popover>
    </div>
  );
  return (
    <div>
      {isDesktop ? (
        <Drawer
          anchor={"left"}
          variant="permanent"
          open={props.open}
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: props.open,
              [classes.drawerClose]: !props.open,
            }),
          }}
          // classes={{
          //   paper: classNames(classes.drawerPaper, {
          //     [classes.drawerPaperRTL]: props.rtlActive
          //   })
          // }}
          onClose={props.handleDrawerToggle}
        >
          {brand}
          {sideNanVisible && (
            <div className={classes.sidebarWrapper}>{links}</div>
          )}
          {props?.poweredBy && (
            <p
              className={classes.poweredByText}
              style={{
                color: "#FFF",
                zIndex: "inherit",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <span>
                <img
                  src={LogoImage}
                  alt="Powered By SAYA"
                  className={classes.poweredByLogo}
                />
              </span>
              <span style={{ display: props.open ? "inline" : "none" }}>
                {props.poweredBy}
              </span>
            </p>
          )}
          <Divider className={classes.devider} />
          {/* {settingsComp} */}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      ) : (
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open={props.open}
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: props.open,
            [classes.drawerMobileClose]: !props.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: props.open,
              [classes.drawerMobileClose]: !props.open,
            }),
          }}
          // classes={{
          //   paper: classNames(classes.drawerPaper, {
          //     [classes.drawerPaperRTL]: props.rtlActive
          //   })
          // }}
          onClose={props.handleDrawerToggle}
        >
          {brand}
          {sideNanVisible && (
            <div className={classes.sidebarWrapper}>{links}</div>
          )}
          <div
            style={{
              color: "#FFF",
              zIndex: "inherit",
              textAlign: "center",
              marginBottom: "5px",
            }}
          >
            {props.poweredBy}
          </div>
          <Divider className={classes.devider} />
          {/* {settingsComp} */}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      )}
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
