import React, { useState, useEffect } from "react";
import axios from "axios";
import { showInfo } from "./DashboardUtil";
import StackedBarChart from "./StackedBarChart";
import ChartSkeltion2 from "./ChartSkeltion2";
import { Grid } from "@mui/material";
import { ReconResolveAPI } from "URL";

function ResolveXDashboard({ userId, selectedOPSProcess, selectedPeriod, selectedSorting }) {
  const [isLoading, setLoading] = useState(false);
  const [resolvexMetrixDetails, setResolvexMetrixDetails] = useState({});

  const { REACT_APP_SPOTLIGHT_RESOLVE_API } = process.env;

  const fetchResolexMetrix = async (
    userId,
    selectedOPSProcess,
    selectedPeriod,
    orgId
  ) => {
    try {
      let url = `${ReconResolveAPI}/workflows/metrics/detail?userId=${userId}&organisationId=${orgId}`;
      const ops = selectedOPSProcess.filter((ops) => ops !== "All");
      if (ops.length > 0) {
        setLoading(true);
        url += `&opsProcessIds=${ops}`;
      }

      if (selectedPeriod.tenorPeriodVal) {
        if (selectedPeriod.tenorPeriodVal === "All") {
        } else if (selectedPeriod.tenorPeriodVal !== "CUSTOM") {
          url += `&period=${selectedPeriod.tenorPeriodVal}`;
        } else {
          url += `&startDate=${selectedPeriod.startDate}&endDate=${selectedPeriod.endDate}`;
        }
      }

      const response = await axios.get(url);
      if (response.status === 200) {
        setLoading(false);
        setResolvexMetrixDetails(response.data);
      }
    } catch (ex) {
      console.error("========>>>>Exception in fetchResolexMetrix", ex.message);
      setLoading(false);
      setResolvexMetrixDetails({});
    }
  };

  useEffect(() => {
    const orgId = JSON.parse(sessionStorage.userData).mstOrganisation
      ?.organisationId;
    if (userId !== 0 && selectedPeriod && orgId) {
      fetchResolexMetrix(userId, selectedOPSProcess, selectedPeriod, orgId);
    }
  }, [userId, selectedOPSProcess, selectedPeriod]);

  if (isLoading) {
    // return showInfo("Chart Loading... Please wait...");
    return <ChartSkeltion2 />;
  }

  if (resolvexMetrixDetails?.averageDaysToResolve) {
    return (
      <div className="grid flex-1 scroll-mt-20 items-start gap-6 md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:gap-6">
        <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
          <StackedBarChart
            {...{
              chartData: resolvexMetrixDetails.queueItemCount || [],
              label: "reconProcessName",
              value: "wfQueueName",
              title: "ResolveX Item count by Queue",
              isQueue: true,
              selectedSorting
            }}
          />
        </div>

        <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
          <StackedBarChart
            {...{
              chartData: resolvexMetrixDetails.averageDaysToResolve || [],
              label: "reconProcessName",
              value: "wfProcessName",
              title: "Average days to resolve",
              selectedSorting
            }}
          />
        </div>
      </div>
    );
  }
  return showInfo(`No data available for the selected criteria.`);
}

export default React.memo(ResolveXDashboard);
