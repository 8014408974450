import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import SayaCombo from "../../components/CustomComboBox/SAYAComboBox";
import SAYAComboBox from "../../components/CustomComboBox/SAYAComboBox";
import LowPriorityOutlinedIcon from "@mui/icons-material/LowPriorityOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Drawer from "@mui/material/Drawer";
import SendIcon from "@mui/icons-material/Send";
import OrganizationChart from "@dabeng/react-orgchart";
import Divider from "@mui/material/Divider";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./WFlowChart/chart.css";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { fetchUserRoles } from "action-handlers/WorkflowActionHandler";
import { fetchWFQueues } from "action-handlers/WorkflowActionHandler";
import { mapRolesWithWorkflows } from "action-handlers/WorkflowActionHandler";
import { notificationParams } from "variables/general";
import {
  showAlertNotification,
  showFailureNotification,
  showSuccessNotification,
} from "action-handlers/NotificationsHandler";
import { fetchMappedRolesForWFProcess } from "action-handlers/WorkflowActionHandler";
import Snackbar from "components/Snackbar/Snackbar";
import { unmapRolesWithQueue } from "action-handlers/WorkflowActionHandler";
import { saveWorkflowProceeQueues } from "action-handlers/WorkflowActionHandler";
import { saveWorkflowActions } from "action-handlers/WorkflowActionHandler";
import { unmapRolesWithAction } from "action-handlers/WorkflowActionHandler";
import ConfigureWFRoles from "./ConfigureWFRoles";
import ConfigureQueueRoles from "./ConfigureQueueRoles";
import ConfigureActions from "./ConfigureActions";
import { fetchUnmappedRolesForQueue } from "action-handlers/WorkflowActionHandler";
import { fetchUnmappedRolesForAction } from "action-handlers/WorkflowActionHandler";
import { SAYAFLOW } from "URL";
import axios from "axios";
import ConfirmationOverlay from "../../components/ConfirmOverlay/ConfirmationOverlay";
import { deleteWorkflowProcessQueues } from "action-handlers/WorkflowActionHandler";
import { deleteWorkflowActions } from "action-handlers/WorkflowActionHandler";
import { fetchLoggedInUserDTO } from "components/utils";
import DndWorkflow from "./DndWorkflow";
import { useTheme } from "@mui/material/styles";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wf-tabpanel-${index}`}
      aria-labelledby={`wf-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wf-tab-${index}`,
    "aria-controls": `wf-tabpanel-${index}`,
  };
}

const NodeComponent = ({
  nodeData,
  addChildNodes,
  removeNode,
  modifyNode,
  levelHandler,
  toggleDrawerHandler,
  allowedPermissions,
}) => {
  nodeData.name = nodeData.name || "Name";
  nodeData.title = nodeData.title || "Title";
  const nodeRef = useRef();

  return (
    <div className={`${nodeData.level === 4 ? "done-node" : ""}`}>
      <div className="node-title">{nodeData.name}</div>
      {/* <div>{nodeData.title}</div> */}
      {nodeData.level === 4 && (
        <div className="done-icon">
          <CheckCircleIcon sx={{ fontSize: "30px", color: "#fff" }} />
        </div>
      )}
      {allowedPermissions.includes("view") && (
        <div className="action-wrapper">
          {nodeData.name !== "RESOLVED" &&
            nodeData.level !== 1 &&
            nodeData.level !== 4 &&
            allowedPermissions.includes("delete") && (
              <Button onClick={() => removeNode(nodeData)}>
                <RemoveCircleOutlineOutlinedIcon />
              </Button>
            )}
          {nodeData.level !== 4 && (
            <Button
              className="edit-btn"
              // onClick={(e) => {
              //   calculateDynamicPosition(e);
              // }}
              onClick={toggleDrawerHandler("right", true, nodeData)}
              ref={nodeRef}
            >
              <TuneOutlinedIcon key="edit" />
            </Button>
          )}
          {(allowedPermissions.includes("modify") ||
            allowedPermissions.includes("delete")) &&
            nodeData.level !== 3 &&
            nodeData.level !== 4 && (
              <Button onClick={() => addChildNodes(nodeData.id, nodeData)}>
                <AddCircleOutlineOutlinedIcon />
              </Button>
            )}
        </div>
      )}
    </div>
  );
};

const WorkflowDetails = () => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userContextData = useContext(UserAuthDetailsContext);
  const { userId, loginName, roleId } = userContextData.state;

  const location = useLocation();
  const { workflows, selectedWorkflow = {} } = location.state;
  const { wfProcessId = "", processName, processDesc } = selectedWorkflow;

  const allowedPermissions = location.state.allowedPermissions || [];
  const orgchart = useRef();
  const navigate = useNavigate();
  const [ds, setDS] = useState({});
  const [workflow, setWorkflow] = useState("");
  const [selectedNodes, setSelectedNodes] = useState(new Set());
  const [newNodes, setNewNodes] = useState([
    { name: "", title: "", target: "" },
  ]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [isMultipleSelect, setIsMultipleSelect] = useState(false);

  const [roles, setRoles] = useState([]);
  const [selectedWFRoles, setSelectedWFRoles] = React.useState([]);

  const [workflowQueues, setWorkflowQueues] = React.useState([]);
  const [workflowActions, setWorkflowActions] = useState([]);

  const [selectedWFQueueRoles, setSelectedWFQueueRoles] = React.useState([]);
  const [allWorkflowActions, setAllWorkflowActions] = useState({});
  const [selectedWFActionRoles, setSelectedWFActionRoles] = useState([]);
  const [showConfirmOverlay, setConfirmOverlay] = React.useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [currentLevel, setCurrentLevel] = React.useState(0);

  const deleteNodeRef = useRef({ selectedNode: null });
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const [dndTreeData, setDndTreeData] = useState([]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor, open, nodeData = {}) =>
    (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      if (nodeData?.level === 2 || nodeData?.level === 3) {
        fetchUnmappedQueueRoles(nodeData);
      }
      setState({ ...state, [anchor]: open });
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const buildRootNode = (name, title, level, children) => {
    setDS({
      id: "wfroot",
      name,
      title,
      level,
      edit: true,
      children,
    });
  };

  const buildQueueNodes = (workflowQueues) => {
    const queues = workflowQueues.map((wfq, index) => {
      return {
        id: `queue${index}`,
        name: wfq.queueName,
        title: wfq.queueDesc,
        level: 2,
        queueId: wfq.queueId,
        edit: true,
        children: [],
      };
    });

    const payload = { ...ds };
    payload.children = queues;
    setDS(payload);
  };

  const callbackUserRoles = (rolesData, apiStatus) => {
    if (apiStatus) {
      setRoles(rolesData);
    }
  };

  const openOverlay = () => {
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };

  useEffect(() => {
    setWorkflow(selectedWorkflow.wfProcessId);
  }, [selectedWorkflow]);

  useEffect(() => {
    const updatdNodes = JSON.parse(JSON.stringify(ds));
    Object.keys(workflowActions).map((wfq) => {
      const targetIndex = updatdNodes.children.findIndex(
        (obj) => obj.queueId === +wfq
      );
      if (targetIndex !== -1) {
        const actions = workflowActions[wfq].map((wfa, index) => {
          const targetObj = workflowQueues.filter(
            (awa) => awa.queueId === wfa.actionResultQueueid
          )[0];
          return {
            id: `action${index + uuidv4()}`,
            name: wfa.actionName,
            title: wfa.actionDesc,
            level: 3,
            actionId: wfa.actionId,
            queueId: +wfq,
            edit: true,
            target: wfa.actionResultQueueid,
            children: !targetObj
              ? []
              : [
                  {
                    id: `target_queue${index}`,
                    name: targetObj.queueName,
                    title: targetObj.queueDesc,
                    level: 4,
                    queueId: +targetObj.queueId,
                    edit: false,
                    lastNode: true,
                    children: [],
                  },
                ],
          };
        });
        updatdNodes.children[targetIndex].children = actions;
      }
    });
    setDS(updatdNodes);
    setDndTreeData(updatdNodes);
  }, [workflowActions]);

  useEffect(() => {
    if (userId && userId !== 0) {
      fetchUserRoles(userId, callbackUserRoles);
    }
  }, [userId]);

  useEffect(() => {
    if (wfProcessId) {
      buildRootNode(processName, processDesc, 1, []);
      fetchWFQueues(wfProcessId, (data) => {
        setWorkflowQueues(data);
      });
      fetchMappedRolesForWFProcess(wfProcessId, (data) => {
        const mappedRoles = data.map((role) => role.roleId);
        setSelectedWFRoles(mappedRoles);
      });
    }
  }, [wfProcessId]);

  // useEffect(() => {
  //   if (roleId && roleId !== 0 && wfProcessId) {
  //     fetchWFQueues(wfProcessId, (data) => {
  //       setWorkflowQueues(data);
  //     });
  //   }
  // }, [roleId, wfProcessId]);

  const fetchWFQueueActions = async () => {
    let payload = {};
    const wfActions = await Promise.all(
      workflowQueues.map(async (wfa) => {
        const response = await axios.get(
          `${SAYAFLOW}/config/wfprocessqueueactions?wfProcessId=${wfProcessId}&queueId=${wfa.queueId}`
        );
        payload[wfa.queueId] = response.data || [];
        return response.data || [];
      })
    );
    setAllWorkflowActions([].concat(...wfActions));
    setWorkflowActions(payload);

    // console.log("=======>>>i final", wfActions);
    // setWorkflowActions(payload);
    // for (let i = 0; i < workflowQueues.length; i++) {
    //   console.log("i======> inside", i);
    //   await fetchQueueActions(
    //     roleId,
    //     wfProcessId,
    //     workflowQueues[i].queueId,
    //     (data) => {
    //       console.log("i======> after res", i);
    //       payload[workflowQueues[i].queueId] = data;
    //       if (i === workflowQueues.length - 1) {
    //         setWorkflowActions(payload);
    //       }
    //     }
    //   );
    // }
  };

  useEffect(() => {
    if (workflowQueues.length > 0) {
      buildQueueNodes(workflowQueues);
      fetchWFQueueActions(workflowQueues);
    }
  }, [workflowQueues]);

  const fetchUnmappedQueueRoles = async (nodeData) => {
    if (nodeData.queueId) {
      fetchUnmappedRolesForQueue(nodeData.queueId, (response, apiStatus) => {
        const mappedqRoles = response.map((role) => role.roleId);
        const queueRoles = selectedWFRoles.filter(
          (wfr) => !mappedqRoles.includes(wfr)
        );
        setSelectedWFQueueRoles(queueRoles);
        if (apiStatus && nodeData.actionId) {
          fetchUnmappedActionRoles(nodeData, queueRoles);
        } else {
          setSelectedWFActionRoles(queueRoles);
        }
      });
    } else {
      setSelectedWFQueueRoles(selectedWFRoles);
    }
  };

  const fetchUnmappedActionRoles = async (nodeData, queueRoles) => {
    fetchUnmappedRolesForAction(nodeData.actionId, (response, apiStatus) => {
      const mappedActions = response.map((role) => role.roleId);
      const actionRoles = queueRoles.filter(
        (wfr) => !mappedActions.includes(wfr)
      );
      setSelectedWFActionRoles(actionRoles);
    });
  };
  const readSelectedNode = (nodeData, flag = true) => {
    //setAnchorEl(nodeData);
    if (isMultipleSelect) {
      setSelectedNodes((prev) => new Set(prev.add(nodeData)));
    } else {
      // if (flag && (nodeData.level === 2 || nodeData.level === 3)) {
      //   fetchUnmappedQueueRoles(nodeData);
      // }

      setSelectedNodes(new Set([nodeData]));
    }
  };

  const clearSelectedNode = () => {
    setAnchorEl(null);
    setSelectedNodes(new Set());
  };

  const onNameChange = (e, nodeId) => {
    const updatedDS = _.cloneDeep(ds);
    const nodeToUpdate = findNodeById(updatedDS, nodeId?.id);
    if (nodeToUpdate) {
      nodeToUpdate.name = e.target.value;
      setDS(updatedDS);
      readSelectedNode(nodeToUpdate, false);
    }
  };

  const onTitleChange = (e, nodeId) => {
    const updatedDS = _.cloneDeep(ds);
    const nodeToUpdate = findNodeById(updatedDS, nodeId?.id);
    if (nodeToUpdate) {
      nodeToUpdate.title = e.target.value;
      setDS(updatedDS);
      readSelectedNode(nodeToUpdate, false);
    }
  };

  const onTargetQueueChange = (e, nodeId) => {
    const updatedDS = _.cloneDeep(ds);
    const nodeToUpdate = findNodeById(updatedDS, nodeId?.id);
    if (nodeToUpdate) {
      nodeToUpdate.target = e.target.value;
      setDS(updatedDS);
      readSelectedNode(nodeToUpdate, false);
    }
  };
  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const unmapQueueRoles = (node) => {
    const payload = {
      roleIdList: selectedWFRoles.filter(
        (role1) => !selectedWFQueueRoles.includes(role1)
      ),
      wfProcessId,
      queueId: node?.queueId,
      loggedInUserDTO: fetchLoggedInUserDTO(),
    };

    unmapRolesWithQueue(payload, (response, apiStatus) => {
      if (apiStatus) {
        showSuccessNotification(response.responseMessage, callbackNotification);
      } else {
        showFailureNotification(response.failureMessage, callbackNotification);
      }
    });
  };

  const saveQueue = async (node = {}, sequenceData = []) => {
    let wfq = [];
    if (node?.edit) {
      wfq = workflowQueues.map((item, index) => {
        if (item.queueId === +node.queueId) {
          return {
            ...item,
            queueName: node.name,
            queueDesc: node.title,
          };
        } else {
          return item;
        }
      });
    } else {
      wfq = [
        ...workflowQueues,
        {
          workflowProcessMaster: location.state?.selectedWorkflow,
          queueName: node?.name,
          queueDesc: node?.title,
        },
      ];
    }

    let payload = {
      workflowQueues: wfq,
      loggedInUserDTO: fetchLoggedInUserDTO(),
    };

    if (sequenceData[0]?.children?.length > 0) {
      let workflowQueuesData = workflowQueues;
      let seqData = sequenceData[0]?.children;
      let seqPayload = [];
      // Create a custom sorting function based on "queueId"
      seqData.forEach((item, index) => {
        const queueData = workflowQueuesData.filter(
          (q) => q.queueId === item.queueId
        )[0];
        seqPayload.push(queueData);
      });

      payload = {
        workflowQueues: seqPayload,
        loggedInUserDTO: fetchLoggedInUserDTO(),
      };
    }

    saveWorkflowProceeQueues(payload, (response, apiStatus) => {
      if (apiStatus) {
        setNewNodes([{ name: "", title: "", target: "" }]);
        if (node.queueId) {
          unmapQueueRoles(node);
        }
        handleClose();
        showSuccessNotification(response.responseMessage, callbackNotification);
        fetchWFQueues(wfProcessId, (data) => {
          setWorkflowQueues(data);
        });
      } else {
        setNewNodes([{ name: "", title: "", target: "" }]);
        showFailureNotification(response.responseMessage, callbackNotification);
      }
    });
  };

  const unmapActionRoles = (node) => {
    const payload = {
      roleIdList: selectedWFQueueRoles.filter(
        (role1) => !selectedWFActionRoles.includes(role1)
      ),
      wfProcessId,
      queueId: node?.queueId,
      actionId: node?.actionId,
      loggedInUserDTO: fetchLoggedInUserDTO(),
    };
    unmapRolesWithAction(payload, (response, apiStatus) => {
      if (apiStatus) {
        showSuccessNotification(response.responseMessage, callbackNotification);
      } else {
        showFailureNotification(response.failureMessage, callbackNotification);
      }
    });
  };
  const saveAction = async (node = {}, sequenceActionData = [], qId) => {
    let wfa = [];
    const isSorting = sequenceActionData?.children?.length > 0;
    if (!node.target && !sequenceActionData?.children?.length > 0) {
      return showFailureNotification(
        "Please Select Target Queue",
        callbackNotification
      );
    }
    if (node.edit) {
      wfa = workflowActions[node.queueId].map((item, index) => {
        if (item.actionId === +node.actionId) {
          return {
            ...item,
            actionName: node.name,
            actionDesc: node.title,
            actionResultQueueid: node.target,
          };
        } else {
          return item;
        }
      });
    } else {
      wfa = [
        ...workflowActions[isSorting ? qId : node.queueId],
        {
          actionResultQueueid: +node.target,
          workflowQueues: workflowQueues.filter(
            (wfq) => wfq.queueId === node.queueId
          )[0],
          actionName: node.name,
          actionDesc: node.title,
        },
      ];
    }
    let payload = {
      workflowActions: wfa,
      loggedInUserDTO: fetchLoggedInUserDTO(),
    };

    if (sequenceActionData?.children?.length > 0) {
      let workflowActionsData = workflowActions[qId];

      let seqActionData = sequenceActionData?.children;
      let seqPayload = [];
      // Create a custom sorting function based on "queueId"
      seqActionData?.forEach((item, index) => {
        const actionData = workflowActionsData?.filter(
          (q) => q.actionId === item.actionId
        )[0];
        actionData.orderSeq = index++;
        seqPayload.push(actionData);
      });

      payload = {
        workflowActions: seqPayload,
        loggedInUserDTO: fetchLoggedInUserDTO(),
      };
    }

    saveWorkflowActions(payload, (response, apiStatus) => {
      if (apiStatus) {
        setNewNodes([{ name: "", title: "", target: "" }]);
        if (node.actionId) {
          unmapActionRoles(node);
        }
        fetchWFQueues(wfProcessId, (data) => {
          setWorkflowQueues(data);
        });
        handleClose();
        showSuccessNotification(response.responseMessage, callbackNotification);
      } else {
        showFailureNotification(response.responseMessage, callbackNotification);
      }
    });
  };

  const callbackMapRolesWithWorkflows = (response) => {
    if (response.responseStatus === "SUCCESS") {
      setNewNodes([{ name: "", title: "", target: "" }]);
      handleClose();
      showSuccessNotification(response.responseMessage, callbackNotification);
    } else {
      showFailureNotification(response.failureMessage, callbackNotification);
    }
  };

  const saveWFRoles = async () => {
    const payload = {
      wfProcessId,
      loggedInUserDTO: fetchLoggedInUserDTO(),
      roleIdList: selectedWFRoles,
    };
    mapRolesWithWorkflows(payload, callbackMapRolesWithWorkflows);
  };

  const addNewNode = (node) => {
    if (node.name.trim() === "" || node.title.trim() === "") {
      showAlertNotification(
        "Please fill required fields",
        callbackNotification
      );
      return;
    }
    if (node.level === 1) {
      saveWFRoles(node);
    } else if (node.level === 2) {
      saveQueue(node);
    } else if (node.level === 3) {
      if (node.target === "") {
        showAlertNotification(
          "Please Select Target Queue",
          callbackNotification
        );
        return;
      }
      saveAction(node);
    }
    setNewNodes((prevNewNodes) => [
      ...prevNewNodes,
      { name: "", title: "", target: "" },
    ]);
    setState({ ...state, ["right"]: open });
  };

  const removeNewNode = (index) => {
    setNewNodes((prevNewNodes) =>
      prevNewNodes.filter((_, idx) => idx !== index)
    );
  };

  const getNewNodes = () => {
    return newNodes.map((node) => ({ ...node, id: uuidv4() }));
  };

  const addChildNodes = (parentId, nodeData) => {
    const parent = findNodeById(ds, parentId);
    if (parent) {
      const newNodesList = getNewNodes();
      newNodesList[newNodesList.length - 1].level = nodeData.level + 1;
      if (nodeData.level === 2) {
        newNodesList[newNodesList.length - 1].queueId = nodeData.queueId;
      }
      if (!parent.children) parent.children = [];
      parent.children.push(...newNodesList);
      setDS(_.cloneDeep(ds));
    }
  };

  const removeNode = (node) => {
    openOverlay(true);
    deleteNodeRef.current.selectedNode = node;
  };

  const findNodeById = (node, id) => {
    if (node.id === id) {
      return node;
    } else if (node.children) {
      for (let child of node.children) {
        const foundNode = findNodeById(child, id);
        if (foundNode) return foundNode;
      }
    }
    return null;
  };

  const onMultipleSelectChange = (e) => {
    setIsMultipleSelect(e.target.checked);
  };

  const onModeChange = (e) => {
    setIsEditMode(e.target.checked);
    if (e.target.checked) {
      orgchart.current.expandAllNodes();
    }
  };

  const closePopover = () => {
    toggleDrawer("right", false);
  };

  const modifyNode = (posObj) => {
    setAnchorEl(posObj);
  };

  const levelHandler = (cLevel) => {
    setCurrentLevel(cLevel);
  };

  // useEffect(() => {
  //   if (userId && userId !== 0) {
  //     fetchUserRoles(userId, callbackUserRoles);
  //   }
  // }, [cLevel]);

  const nodeTitleHandler = (typeVal) => {
    let nTitle = "";
    if (typeVal === 1) {
      nTitle = "Workflow";
    } else if (typeVal === 2) {
      nTitle = "Queue";
    } else if (typeVal === 3) {
      nTitle = "Action";
    }
    return nTitle;
  };

  const deleteNode = () => {
    const updatedDS = _.cloneDeep(ds);

    const findNodeAndRemove = (nodes) => {
      if (nodes.children) {
        nodes.children = nodes.children.filter(
          (child) => child.id !== deleteNodeRef.current.selectedNode.id
        );
        nodes.children.forEach(findNodeAndRemove);
        closeOverlay();
      }
    };

    findNodeAndRemove(updatedDS);
    if (deleteNodeRef.current.selectedNode !== null) {
      if (deleteNodeRef.current.selectedNode.level === 2) {
        const deleteQueue = workflowQueues.filter(
          (wfq) => wfq.queueId === deleteNodeRef.current.selectedNode.queueId
        );
        const payload = {
          workflowQueues: deleteQueue,
          loggedInUserDTO: fetchLoggedInUserDTO(),
        };

        if (deleteQueue.length === 0) {
          setDS(updatedDS);
          setSelectedNodes(new Set());
          return;
        }
        deleteWorkflowProcessQueues(payload, (response, apiStatus) => {
          if (apiStatus) {
            if (apiStatus) {
              showSuccessNotification(
                response.responseMessage,
                callbackNotification
              );
              fetchWFQueues(wfProcessId, (data) => {
                setWorkflowQueues(data);
              });
            } else {
              showFailureNotification(
                response.responseMessage,
                callbackNotification
              );
            }
          }
        });
      } else if (deleteNodeRef.current.selectedNode.level === 3) {
        const deleteAction = workflowActions[
          deleteNodeRef.current.selectedNode.queueId
        ].filter(
          (wfa) => wfa.actionId === deleteNodeRef.current.selectedNode.actionId
        );
        const payload = {
          workflowActions: deleteAction,
          loggedInUserDTO: fetchLoggedInUserDTO(),
        };
        if (deleteAction.length === 0) {
          setDS(updatedDS);
          setSelectedNodes(new Set());
          return;
        }
        deleteWorkflowActions(payload, (response, apiStatus) => {
          if (apiStatus) {
            fetchWFQueues(wfProcessId, (data) => {
              setWorkflowQueues(data);
            });
            showSuccessNotification(
              response.responseMessage,
              callbackNotification
            );
          } else {
            showFailureNotification(
              response.responseMessage,
              callbackNotification
            );
          }
        });
      }
    }
    setDS(updatedDS);
    setSelectedNodes(new Set());
  };

  const dndQueueDataHandler = (data) => {
    saveQueue({}, data, "sorting");
  };

  const dndActionDataHandler = (nodeObj, data) => {
    const queueData = data[0]?.children;

    const currentActionObj = queueData.filter(
      (qItem) => qItem.queueId === nodeObj?.queueId
    )[0];
    saveAction({}, currentActionObj, nodeObj?.queueId, "sorting");
  };

  const selectWorkflow = (event) => {
    const { value } = event.target;
    const targetWorkflow = workflows.filter(
      (wf) => wf.wfProcessId === +value
    )[0];
    navigate(
      `/saya-platform/admin/workflows/configure?wfid=${targetWorkflow?.wfProcessId}`,
      {
        state: {
          selectedWorkflow: targetWorkflow,
          allowedPermissions: allowedPermissions,
          workflows: workflows,
        },
      }
    );
  };
  return (
    <Box
      className="workflow-detail-wrapper"
      sx={{
        width: "100%",
        p: 0,
        backgroundColor:
          theme.palette.type === "dark"
            ? "transparent"
            : "rgb(255 255 255 / 55%)",
      }}
    >
      <div className="header-box">
        <div style={{marginRight: '10px'}}>
          <SAYAComboBox
            optionsData={workflows.map((option) => ({
              value: option.wfProcessId,
              label: option.processName,
            }))}
            value={workflow}
            labelText="Select Workflow"
            handleChange={selectWorkflow}
            headerCombo
          />
        </div>
        <div className="legend-wrapper" style={{ marginTop: "14px" }}>
          <div className="legend-item workflow">
            <span></span>
            <label>Workflow</label>
          </div>
          <div className="legend-item queue">
            <span></span>
            <label>Queue</label>
          </div>
          <div className="legend-item action">
            <span></span>
            <label>Action</label>
          </div>
          <div className="legend-item target-queue">
            <span></span>
            <label>Target Queue</label>
          </div>
        </div>
      </div>
      <Box sx={{}}>
        <Tabs
          className="workflow-tabs"
          centered
          value={value}
          onChange={handleChange}
          aria-label="workflow tabs"
        >
          <Tab
            iconPosition="start"
            icon={<LanOutlinedIcon />}
            label="Chart View"
            {...a11yProps(0)}
          />
          <Tab
            iconPosition="start"
            icon={<LowPriorityOutlinedIcon />}
            label="Change Sequence"
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="edit-chart-wrapper">
          <Drawer
            //hideBackdrop={true}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: "rgb(255 255 255 / 50%)",
                },
              },
            }}
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            <section className={`toolbar level${currentLevel}`}>
              {/* <div className="selected-nodes">
            <div>
              <h4 style={{ display: "inline-block" }}>Selected Node</h4>
              <input
                style={{ marginLeft: "1rem" }}
                id="cb-multiple-select"
                type="checkbox"
                checked={isMultipleSelect}
                onChange={onMultipleSelectChange}
              />
              <label htmlFor="cb-multiple-select">Multiple Select</label>
            </div>
            <ul>
              {Array.from(selectedNodes).map((node) => (
                <li key={node.id}>
                  {node.name} - {node.title}
                </li>
              ))}
            </ul>
          </div> */}
              <div className="new-nodes">
                <h5>
                  Edit {nodeTitleHandler([...selectedNodes.values()][0]?.level)}
                </h5>
                <ul>
                  {selectedNodes &&
                    selectedNodes.map((node, keyIndex) => (
                      <li key={keyIndex}>
                        <div className="node-input-wrapper">
                          <div>
                            <div>
                              <span>Name</span>
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                              type="text"
                              placeholder="name"
                              value={node.name}
                              disabled={
                                node.level === 1 || node.name === "RESOLVED"
                              }
                              required
                              onChange={(e) => onNameChange(e, keyIndex)}
                            />
                          </div>
                          <div>
                            <div>
                              <span>Description</span>
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <input
                              type="text"
                              placeholder="title"
                              value={node.title}
                              disabled={
                                node.level === 1 || node.name === "RESOLVED"
                              }
                              required
                              onChange={(e) => onTitleChange(e, keyIndex)}
                            />
                          </div>
                        </div>
                        {node.level === 3 && (
                          <div className="queue-list-wrapper">
                            <div>
                              <span>Target Queue</span>
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <select
                              className="queue-list"
                              value={node.target}
                              name="target"
                              onChange={(e) => onTargetQueueChange(e, keyIndex)}
                            >
                              <option value="">Select Queue</option>
                              {workflowQueues
                                .filter((wfq) => wfq.queueId !== node.queueId)
                                .map((option) => {
                                  return (
                                    <option
                                      value={option.queueId}
                                      key={option.queueId}
                                    >
                                      {option.queueName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )}
                        {node.level === 1 ||
                        (node.level == 2 && node.queueId) ||
                        (node.level == 3 && node.actionId) ? (
                          <h5 className="heading">Roles</h5>
                        ) : null}
                        {node.level === 1 && (
                          <ConfigureWFRoles
                            {...{
                              roles,
                              selectedWFRoles,
                              setSelectedWFRoles,
                            }}
                            key={node.id}
                          />
                        )}
                        {node.level == 2 && node.queueId && (
                          <ConfigureQueueRoles
                            {...{
                              roles,
                              selectedWFRoles,
                              selectedWFQueueRoles,
                              setSelectedWFQueueRoles,
                            }}
                            key={node.id}
                          />
                        )}
                        {node.level == 3 && node.actionId && (
                          <ConfigureActions
                            {...{
                              roles,
                              selectedWFQueueRoles,
                              selectedWFActionRoles,
                              setSelectedWFActionRoles,
                            }}
                            key={node.id}
                          />
                        )}
                        <Divider sx={{ mt: 2 }} />

                        <div className="btn-wrapper">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={(e) => addNewNode(node)}
                          >
                            <SendIcon sx={{ fontSize: "14px", mr: "5px" }} />{" "}
                            Submit
                          </Button>
                          <Button
                            sx={{ ml: "10px" }}
                            variant="outlined"
                            size="small"
                            onClick={toggleDrawer("right", false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="action-buttons">
                {/* <button disabled={!isEditMode} onClick={remove}>
              Remove Nodes
            </button> */}
                {/* <input
              style={{ marginLeft: "1rem" }}
              id="cb-mode"
              type="checkbox"
              checked={isEditMode}
              onChange={onModeChange}
            />
            <label htmlFor="cb-mode">Edit Mode</label> */}
              </div>
            </section>
          </Drawer>
          {/* </Popover> */}

          <OrganizationChart
            ref={orgchart}
            datasource={ds}
            collapsible={!isEditMode}
            pan={true}
            zoom={true}
            draggable={false}
            multipleSelect={isMultipleSelect}
            onClickNode={readSelectedNode}
            onClickChart={clearSelectedNode}
            NodeTemplate={(props) => (
              <div>
                <NodeComponent
                  {...props}
                  addChildNodes={addChildNodes}
                  removeNode={removeNode}
                  modifyNode={modifyNode}
                  levelHandler={levelHandler}
                  toggleDrawerHandler={toggleDrawer}
                  allowedPermissions={allowedPermissions}
                />
              </div>
            )}
            extraRenderers={[
              {
                renderExtra: () => (
                  <button
                    disabled={!isEditMode}
                    onClick={() => addChildNodes(ds.id)}
                  >
                    Add Child Nodes
                  </button>
                ),
                position: "node",
              },
            ]}
          />

          {showConfirmOverlay && (
            <ConfirmationOverlay
              {...{
                isOverlayOpen: showConfirmOverlay,
                closeOverlay,
                handler: deleteNode,
                title: "DELETE",
                message: `Are you sure you want to delete node. You will lose all associated data`,
              }}
            />
          )}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {dndTreeData?.children?.length > 0 && (
          <DndWorkflow
            data={dndTreeData}
            queueDataChangeHandler={dndQueueDataHandler}
            actionDataChangeHandler={dndActionDataHandler}
          />
        )}
      </CustomTabPanel>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </Box>
  );
};

export default WorkflowDetails;
