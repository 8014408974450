import React, { useContext, useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer.js";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import Tile from "components/v2/Tile";
import BasicSelect from "components/v2/Select";
import {
  FolderSync,
  FolderCheck,
  ChartPie,
  Database,
  ChartNoAxesCombined,
} from "lucide-react";
import axios from "axios";
import { Box } from "@mui/material";
import useDebounce from "hooks/useDebounce.js";
import ReconXDashboard from "./ReconXDashboard.js";
import ResolveXDashboard from "./ResolveXDashboard.js";
import AnalyticsDashboard from "./AnalyticsDashboard.js";
import DataXDashboard from "./DataXDashboard.js";
import { showInfo } from "./DashboardUtil.js";
import DaysDatePicker from "components/DaysDatePicker/index.js";
import { API } from "URL.js";
import { ReconResolveAPI } from "URL.js";
import { useNavigate } from "react-router-dom";
import BarChart from "./BarChart";

export default function Dashboard() {
  const navigate = useNavigate();
  const [opsProcesses, setOPSProcesses] = useState([]);
  const [selectedOPSProcess, setSelectedOPSProcess] = useState([]);
  const [isAllBlocked, setAllBlocked] = useState(false);
  const [dashboardData, setDashboardData] = useState([
    {
      type: "ReconX",
      count: 0,
      isActive: false,
      title: "Recon Processes",
      isVisible: false,
      Icon: FolderSync,
      isDisabled: false,
    },
    {
      type: "ResolveX",
      count: 0,
      isActive: false,
      title: "Resolve Processes",
      isVisible: false,
      Icon: FolderCheck,
      isDisabled: false,
    },
    {
      type: "AnalytiX",
      count: 0,
      isActive: false,
      title: "Analytix Insights",
      isVisible: false,
      Icon: ChartNoAxesCombined,
      isDisabled: false,
    },
    {
      type: "DataX",
      count: 0,
      isActive: false,
      title: "Configured DataX",
      isVisible: false,
      Icon: Database,
      isDisabled: true,
    },
  ]);
  const [selectedPeriod, setSelectedPeriod] = useState({
    startDate: null,
    endDate: null,
    tenorPeriodVal: "P-7D",
  });
  const [selectedSorting, setSelectedSorting] = useState("Descending");

  const period = [
    { label: "All Time", value: "All" },
    { label: "Last 1 Day", value: "P-1D" },
    { label: "Last 7 Days", value: "P-7D" },
    { label: "Last 30 Days", value: "P-30D" },
    { label: "Last 90 Days", value: "P-90D" },
    { label: "Last 180 Days", value: "P-180D" },
    { label: "Last 1 Year", value: "P-1Y" },
  ];

  const sortOptions = [
    { key: "Descending", value: "Descending" },
    { key: "Ascending", value: "Ascending" },
  ];

  const { REACT_APP_SPOTLIGHT_ANALYTIX_API } = process.env;

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { userId, originalMenuHierarchy = [] } = UserAuthContext.state;
  const activeItem = dashboardData.filter((dd) => dd.isActive)[0];

  const fetchOPSProcesses = async (userId) => {
    try {
      const response = await axios.get(`${API}/reconunits/byuser/${userId}`);
      if (response.status === 200) {
        setOPSProcesses(response.data);
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
    }
  };

  const fetchReconxMetrixSummary = async (userId) => {
    try {
      const response = await axios.get(
        `${API}/recon/metrics/summary?userId=${userId}`
      );
      if (response.status === 200) {
        setDashboardData((prevData) =>
          prevData.map((item, index) =>
            index === 0
              ? { ...item, count: response.data?.reconProcessesCount || 0 }
              : item
          )
        );
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
    }
  };

  const fetchResolvexMetrixSummary = async (userId) => {
    try {
      const response = await axios.get(
        `${ReconResolveAPI}/workflows/metrics/summary?userId=${userId}`
      );
      if (response.status === 200) {
        setDashboardData((prevData) =>
          prevData.map((item, index) =>
            index === 1
              ? { ...item, count: response.data?.workflowProcessesCount || 0 }
              : item
          )
        );
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
    }
  };

  const fetchAnalytixMetrixSummary = async () => {
    try {
      const orgId = JSON.parse(sessionStorage.userData).mstOrganisation
        ?.organisationId;

      const roleId = JSON.parse(sessionStorage.userData).mstRole?.roleId;
      if (orgId && roleId) {
        const response = await axios.get(
          `${API}/reconanalytix/metrics/summary?roleId=${roleId}&organisationId=${orgId}`
        );
        if (response.status === 200) {
          setDashboardData((prevData) =>
            prevData.map((item, index) =>
              index === 2
                ? { ...item, count: response.data?.insightsCount || 0 }
                : item
            )
          );
        }
      }
    } catch (ex) {
      console.error("Exception in fetchAnalytixMetrixSummary", ex.message);
    }
  };

  useEffect(() => {
    if (userId !== 0) {
      fetchOPSProcesses(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (opsProcesses.length > 0) {
      if (activeItem && activeItem.type === "ReconX") {
        const reconOPS = opsProcesses
          .filter((ops) => ops.reconEnabled)
          .map((ops) => ops.reconUnitId);
        setSelectedOPSProcess(reconOPS);
      } else if (activeItem && activeItem.type === "ResolveX") {
        const reconOPS = opsProcesses
          .filter((ops) => ops.workflowEnabled)
          .map((ops) => ops.reconUnitId);
        setSelectedOPSProcess(reconOPS);
      }
    }
  }, [activeItem?.type, opsProcesses]);

  function updateCanShow(menuHierarchy, dashboardData) {
    const specificIdentifiers = ["ReconX", "ResolveX", "AnalytiX", "DataX"];
    const result = {};

    menuHierarchy.forEach((menu) => {
      if (specificIdentifiers.includes(menu.menuIdentifier)) {
        result[menu.menuIdentifier] = menu.allowedPermissions;
      }
    });
    dashboardData.forEach((item) => {
      if (result[item.type]?.includes("view")) {
        item.isVisible = true;
      }
    });

    return dashboardData;
  }

  useEffect(() => {
    if (originalMenuHierarchy.length > 0) {
      const updatedDashboardData = updateCanShow(
        originalMenuHierarchy,
        dashboardData
      );
      const activeItems = updatedDashboardData.filter((di) => di.isVisible);
      if (activeItems.length > 0) {
        activeItems[0].isActive = true;
        setDashboardData(activeItems);
        for (let i = 0; i < activeItems.length; i++) {
          if (activeItems[i].type == "ReconX") {
            fetchReconxMetrixSummary(userId);
          } else if (activeItems[i].type === "ResolveX") {
            fetchResolvexMetrixSummary(userId);
          } else if (activeItems[i].type === "AnalytiX") {
            fetchAnalytixMetrixSummary();
          }
        }
      } else {
        setDashboardData(updatedDashboardData);
        setAllBlocked(true);
      }
    }
  }, [originalMenuHierarchy.length]);

  const handleOPS = (values) => {
    // if (values.length === 0) {
    //   setSelectedOPSProcess(["All"]);
    // } else
    if (values.length > 0) {
      setSelectedOPSProcess(values.filter((v) => v !== "All"));
    } else {
      setSelectedOPSProcess(values);
    }
  };

  const handlePeriod = (value) => {
    setSelectedPeriod(value);
  };

  const handleSorting = (value) => {
    setSelectedSorting(value);
  };

  // const debouncedFetch = useDebounce((userId, opsProcess, period) => {
  //   fetchReconxMetrix(userId, opsProcess, period);
  // }, 500);

  // useEffect(() => {
  //   if (
  //     activeItem &&
  //     activeItem.type === "ReconX" &&
  //     userId !== 0 &&
  //     selectedPeriod
  //   ) {
  //     debouncedFetch(userId, selectedOPSProcess, selectedPeriod);
  //   }
  // }, [activeItem, userId, selectedOPSProcess, selectedPeriod, debouncedFetch]);

  // On click of Resolvex tile

  const handleTileClick = (tileData = {}) => {
    if (tileData?.type === "DataX") {
      navigate(`/saya-platform/data-ingestion`);
      return;
    }
    if (tileData?.type !== activeItem?.type) {
      setSelectedOPSProcess([]);
      setDashboardData((prevState) =>
        prevState.map((tile) =>
          tile.type === tileData.type
            ? { ...tile, isActive: true }
            : { ...tile, isActive: false }
        )
      );
    }
  };

  if (isAllBlocked) {
    return showInfo(
      "No applications are available. Please contact your administrator for access."
    );
  }

  return (
    <Box>
      <div className="bg-gradient-to-b pb-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {dashboardData.map((item, index) => {
            if (item.isVisible) {
              return <Tile {...{ item, click: handleTileClick }} key={index} />;
            }
            return null;
          })}
        </div>
      </div>

      {activeItem && activeItem.type !== "AnalytiX" && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 relative z-10">
          <div className="space-y-2">
            <BasicSelect
              {...{
                label: "Ops Process",
                name: "Ops Process",
                value: selectedOPSProcess,
                selectData:
                  activeItem.type === "ReconX"
                    ? opsProcesses.filter((ops) => ops.reconEnabled)
                    : activeItem.type === "ResolveX"
                    ? opsProcesses.filter((ops) => ops.workflowEnabled)
                    : opsProcesses,
                optionLabel: "clientReconName",
                optionValue: "reconUnitId",
                onChange: handleOPS,
                isMulti: true,
              }}
            />
          </div>

          <div className="space-y-2 period-date-picker">
            <DaysDatePicker
              onDateChange={handlePeriod}
              showFormObject={false}
              tenorPeriod={selectedPeriod.tenorPeriodVal}
              options={period}
            />
          </div>
          {/* {activeItem.type === "ResolveX" && (
            <div className="space-y-2">
              <BasicSelect
                {...{
                  label: "Sort",
                  name: "sort",
                  value: selectedSorting,
                  selectData: sortOptions,
                  optionLabel: "key",
                  optionValue: "value",
                  onChange: handleSorting,
                }}
              />
            </div>
          )} */}
        </div>
      )}

      {selectedOPSProcess.length > 0 ? (
        <div className="my-6">
          {activeItem &&
            activeItem.type === "ReconX" &&
            selectedOPSProcess.length > 0 && (
              <ReconXDashboard
                {...{
                  userId,
                  selectedOPSProcess,
                  selectedPeriod,
                  selectedSorting,
                }}
              />
            )}

          {activeItem &&
            activeItem.type === "ResolveX" &&
            selectedOPSProcess.length > 0 && (
              <ResolveXDashboard
                {...{
                  userId,
                  selectedOPSProcess,
                  selectedPeriod,
                  selectedSorting,
                }}
              />
            )}
        </div>
      ) : (
        activeItem &&
        activeItem.type !== "AnalytiX" && (
          <div className="my-6 grid flex-1 scroll-mt-20 items-start gap-6 md:grid-cols-2 md:gap-6 lg:grid-cols-2 xl:gap-6">
            <div className="bg-white  themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out hover:z-30">
              <div className="h-[calc(100%-4rem)] leading-5 mx-auto text-center text-sm items-center  justify-center p-2 md:p-6 pb-8 min-h-96 flex">
                Please select any one of the Ops Process <br />
                from the above options
              </div>
            </div>

            <div className="bg-white themes-wrapper group relative flex flex-col overflow-hidden rounded-xl border shadow transition-all duration-200 ease-in-out">
              <div className="h-[calc(100%-4rem)] text-sm mx-auto leading-5 text-center  items-center justify-center  p-2 md:p-6 pb-8 min-h-96 flex">
                Please select any one of the Ops Process <br />
                from the above options
              </div>
            </div>
          </div>
        )
      )}

      {activeItem && activeItem.type === "AnalytiX" && (
        <div className="my-6">
          <AnalyticsDashboard {...{ userId }} />
        </div>
      )}
    </Box>
  );
}
