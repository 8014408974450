export default function ChartSkeltion() {
  return (
    <div className="grid gap-6 md:grid-cols-2">
      {/* Bar Chart Skeleton */}
      <div className="bg-white rounded-lg shadow">
        {/* Title */}
        <div className="p-4">
          <div className="h-3 w-[40%] bg-gray-200 rounded animate-pulse" />
        </div>
        <div className="border border-gray-100"></div>
        <div className="p-6 mt-6">
          <div className="space-y-8">
            {/* First Bar */}
            <div className="space-y-2">
              <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
              <div className="h-8 w-[90%] bg-gray-200 rounded animate-pulse" />
            </div>

            {/* Second Bar */}
            <div className="space-y-2">
              <div className="h-4 w-32 bg-gray-200 rounded animate-pulse" />
              <div className="h-8 w-[70%] bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
        </div>
      </div>

      {/* Donut Chart Skeleton */}
      <div className="bg-white rounded-lg shadow">
        {/* Title */}
        <div className="p-4">
          <div className="h-3 w-[40%] bg-gray-200 rounded animate-pulse" />

        </div>
        <div className="border border-gray-100"></div>
        {/* Donut Chart Circle */}
        <div className="flex items-center justify-center p-6">
          <div className="relative">
            {/* Outer circle */}
            <div className="h-[300px] w-[300px] rounded-full bg-gray-200 animate-pulse" />
            {/* Inner circle (creates donut hole) */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[200px] w-[200px] rounded-full bg-white" />
          </div>
        </div>
        <div className="p-6">
          {/* Legend */}
          <div className="flex justify-center gap-4 mt-4">
            {/* First Legend Item */}
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-full bg-gray-200 animate-pulse" />
              <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
            </div>

            {/* Second Legend Item */}
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-full bg-gray-200 animate-pulse" />
              <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes shimmer {
          0% {
            background-position: -1000px 0;
          }
          100% {
            background-position: 1000px 0;
          }
        }
        .animate-pulse {
          background: linear-gradient(
            90deg,
            rgba(229, 231, 235, 0.6) 25%,
            rgba(229, 231, 235, 0.8) 37%,
            rgba(229, 231, 235, 0.6) 63%
          );
          background-size: 1000px 100%;
          animation: shimmer 2s infinite linear;
        }
      `}</style>
    </div>
  );
}
