const assignedReconUnitData = [];

const reconUnitFormData = {
  input: {
    clientReconName: "",
    reconUnitId: "",
    multimatchAggrBuffer: 1,
    identicalData: false,
    headerData: true,
    workflowEnabled: false,
    reconEnabled: true,
    multimatchReqd: true,
    oneToMany: true,
    manyToOne: true,
    dataSourceId1: 1,
    dataSrcConfigId1: "Data Source - 1",
    dataSourceId2: 2,
    dataSrcConfigId2: "Data Source - 2",
    createdBy: 0,
    createdDate: "",
    updatedDate: "",
    mlEnabled: true,
  },
  validationErrs: {
    clientReconName: "",
    identicalData: "",
    multimatchAggrBuffer: "",
    headerData: "",
    workflowEnabled: "",
    reconEnabled: "",
    multimatchReqd: "",
    oneToMany: "",
    manyToOne: "",
    createdBy: 0,
    createdDate: "",
    dataSrcConfigId1: "",
    dataSrcConfigId2: "",
  },
  isSubmiting: false,
};

const reconUnitDropDownData = [];

var dataSourceDropDownData = [
  { value: 1, label: "Data Source -1", isdisabled: false },
  { value: 2, label: "Data Source -2", isdisabled: false },
];

const landingPageReconCardData = [];

const landingPageResolveCardData = {};

const reconTrendsDataDashBoard = {
  labels: [],
  series: [[]],
};

const resolveOpenTrendsDataDashBoard = {
  labels: [],
  series: [[]],
};

const resolveCloseTrendsDataDashBoard = {
  labels: [],
  series: [[]],
};

module.exports = {
  assignedReconUnitData,
  reconUnitDropDownData,
  dataSourceDropDownData,
  landingPageReconCardData,
  landingPageResolveCardData,
  reconTrendsDataDashBoard,
  resolveOpenTrendsDataDashBoard,
  resolveCloseTrendsDataDashBoard,
  reconUnitFormData,
};
