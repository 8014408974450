import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
// @material-ui/core components
// import ClickAwayListener from "@mui/base/ClickAwayListener";
import Loader from "../../components/Loader/Loader";
import { KeyboardBackspace, List } from "@mui/icons-material";
import classNames from "classnames";
import {
  Grow,
  TextareaAutosize,
  Typography,
  Button,
  ClickAwayListener,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { MenuList } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
//import PopperUnstyled from "@mui/base/PopperUnstyled";

import Popper, { PopperProps } from "@mui/material/Popper";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// core components
import SAYATable from "../../components/Table/MuiDataTable";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import DynamicFormClass from "../../components/CustomSayaComponents/DynamicFormClass";
import SayaSwitch from "../../components/Switch/CustomSwitch";
import SayaEmailDialog from "../../components/CustomDialog/SayaCustomEmailDialog";
import Snackbar from "../../components/Snackbar/Snackbar";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { resolveDropDownData } from "../../variables/ResolveDataVariables";
import { columns, data } from "../../variables/general";
import { CheckAccess } from "../../components/Permissions/CheckAccess";
import SayaCombo from "../../components/CustomComboBox/SAYAComboBox";
import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle";
import reconStyles from "../ReconXWorkspace/ReconStyles";
import {
  fetchResolveRecords,
  fetchFormData,
  fetchLoadAuditData,
  triggerFollowupAction,
  fetchWorkflowQueues,
  getResolveWorkItemData,
  fetchQueueActions,
  triggerDoAction,
  fetchReconProcessesByWorkflow,
  getResolveCount,
  getResolveData,
} from "../../action-handlers/ResolveXActionHandler";
import { notificationParams } from "../../variables/general";
import {
  showAlertNotification,
  showFailureNotification,
  showSuccessNotification,
} from "../../action-handlers/NotificationsHandler";
import {
  API_RESOLVEX_QUEUES_FAILED,
  API_LOADFORM_FAILED,
  API_AUDITINFO_FAILED,
  USER_SESSION_TIME_OUT,
  API_FETCH_RESOLVE_ACTIONS_CONFIG_FAILED,
  API_RECON_PROCESS_FAILED,
} from "../../messages/DashboardMessages";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { WorkflowContext } from "../../context/Workflow/WorkflowContext";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import { fetchReconUnitsData } from "action-handlers/DashboardActionHandler";
import {
  cellHandler,
  checkAccessHandler,
  transformColumnName,
} from "../../components/utils";

import ResolveXQueues from "./ResolveXQueues";
import Overlay from "components/Overlay/Overlay";
import MaterialReactTable from "material-react-table";
import { useInfiniteQuery } from "@tanstack/react-query";
import AddForm from "./AddForm";
import { fetchmappedFlowUnits } from "action-handlers/ReconXActionHandler";
import MetadataTabs from "./MetadataTabs";
import { API } from "URL";
import axios from "axios";

const useStyles = makeStyles(styles);
const reconUseStyles = makeStyles(reconStyles);

var perPageRows = 100;
var currentPage = 0;
var userDepartmentName = "";
var globalResolveQueueData = resolveDropDownData;
var globalResolveQueueSelected = "";
var globalResolveQueueName = "";
var globalFlowIdSelected = "";
var emailFormToAddress = "";
var emailFormSubject = "";
var emailMessage = "";
var globalDropDownData = [];

export default function ResolveXWorkSpace(props) {
  const UserAuthContext = React.useContext(UserAuthDetailsContext);
  const classes = useStyles();
  const reconClasses = reconUseStyles();
  const { allowedPermissions } = props && props.routeData;
  var auditOptions = {
    filterType: "dropdown",
    selectableRows: "none", //single |multiple| none
    rowsPerPage: UserAuthContext?.state?.rowsPerPage || perPageRows,
    page: 0,
    rowsPerPageOptions: [100, 500, 1000],
    print: false,
  };

  var options = {
    textLabels: {
      body: {
        noMatch: "No Data Found!!!",
      },
    },
    filterType: "dropdown",
    pagination: true,
    selectableRows: "none", //single |multiple| none
    rowsPerPage: UserAuthContext?.state?.rowsPerPage || perPageRows,
    resizableColumns: false,
    draggableColumns: {
      enabled: true,
    },
    page: currentPage,
    rowsPerPageOptions: [100, 500, 1000],
    jumpToPage: false,
    onChangePage: (page) => {
      handleChangePage(page);
    },
    onTableInit: (status, data) => {
      if (data?.data.length > 0) {
        setResolveXLoading(false);
      } else {
        setResolveXLoading(true);
      }
    },
    print: false,
    tableBodyMaxHeight: "450px",
    tableBodyHeight: "450px",
    //serverside is set as true just for pagination
    //we need to explicitly set to to false to have the filters work properly
    // we can revert it back to true always once the server side filter is done
    serverSide: false,
    responsive: "standard", //TODO scroll is depricated it needs to be replaced with standard however the fixed header or sticky header to be worked out
    fixedHeader: true,
    hint: "Double click on row for actions on the row item",
    setRowProps: (row, dataIndex) => ({
      style: {
        cursor: checkAccessHandler("double click records", allowedPermissions)
          ? "pointer"
          : "default",
      },
      onDoubleClick: (evt) => {
        if (checkAccessHandler("double click records", allowedPermissions)) {
          handleRowDoubleClick(row);
        } else {
          return false;
        }
      },
    }),
    onRowClick: (rowData) => mobileRowClickHandler(rowData),
  };

  const mobileRowClickHandler = (row) => {
    if (window.innerWidth <= 1024) {
      handleRowDoubleClick(row);
    }
  };

  const WorkflowDataContext = React.useContext(WorkflowContext);

  const [tableAuditData, setTableAuditData] = useState([]);
  const [resolveActionsConfigList, setResolveActionsConfigList] = useState([]);
  const [tableAuditColumns, setTableAuditColumns] = useState([]);
  const [auditTableOptions, setAuditTableOptions] = useState(auditOptions);
  const [resolveQueueList, setResolveQueueList] = useState(resolveDropDownData);
  const [selectedResolveQueue, setSelectedResolveQueue] = useState(
    UserAuthContext.state.resolveUnitId || 0
  );
  const [selectedQueueName, setSelectedQueueName] = useState();
  const [selectedQueueId, setSelectedQueueId] = useState("");
  const [rowDetailFormObject, setRowDetailFormObject] = useState({});
  const [isResolveWorkSpaceLoading, setResolveWorkSpaceLoading] =
    useState(true);
  const [showFormObject, setShowFormObject] = useState(false);
  const [openResolveActions, setOpenResolveActions] = useState();
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [tableColumns, setTableColumns] = useState(columns);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(options);
  //const [isItemResolvable, setItemResolvable] = useState(true);
  const [isFollowupDialogOpen, setFollowupDialogOpen] = useState(false);
  const [toAddress, setToAddress] = useState();
  const [emailSubject, setEmailSubject] = useState();
  const [message, setMessage] = useState();
  const [emailLoader, setEmailLoader] = useState(false);
  const [isResolveXLoading, setResolveXLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabName, setActiveTabName] = useState("");
  const [activeDSTab, setActiveDSTab] = useState(0);
  const [activeDSTabName, setActiveDSTabName] = useState("");
  const [showOverlay, setOverlay] = useState(false);
  const [userNotes, setUserNotes] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 500, //customize the default page size
  });
  const rowVirtualizerInstanceRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState();
  const [sorting, setSorting] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [resolveData, setResolveData] = useState({
    selectedOpsProcessData: null,
  });
  const { userId, departmentId, roleId, loginName } = UserAuthContext.state;
  let ref = useRef({
    actionItem: "",
  });
  let apiFlagRef = useRef({ canFetchData: false });
  let rowRef = useRef({ rowData: [] }); //table rows data
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [dataCounts, setDataCounts] = useState({});
  const columnRef = useRef({ columns: {} });
  const pageDetailsRef = useRef({ page: 0, fetchedRecords: 0 });
  const [resolveWorkItemData, setResolveWorkItemData] = useState({});
  const [selectedReconUnit, setSelectedReconUnit] = useState(
    UserAuthContext.state.reconUnitId || 0
  );

  const queuesData = WorkflowDataContext.state?.queues;

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const ops = query.get("ops");
  const wf = query.get("wf");

  const closeOverlay = () => {
    setOverlay(false);
    setUserNotes("");
  };

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  };

  const changeTab = React.useCallback(
    async (menu) => {
      setActiveTabName(menu.queueName);
      setSelectedQueueId(menu.queueId);
      handleBackToReSolveXSpace();
    },
    [activeTab]
  );

  const handleDSTabChange = (e, value) => {
    setActiveDSTab(value);
  };

  const changeDSTab = React.useCallback(
    async (name, index) => {
      setActiveDSTabName(name);
      setActiveDSTab(index);
    },
    [activeDSTab]
  );

  useEffect(() => {
    if (WorkflowDataContext.state?.queues?.length > 0) {
      setActiveTabName(WorkflowDataContext.state?.queues[0]?.queueName);
      setSelectedQueueId(WorkflowDataContext.state?.queues[0]?.queueId);
    }
  }, [WorkflowDataContext.state?.queues]);

  /******************* */

  function debounce(func, timeout = 1000) {
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this);
    }, timeout);
  }

  const {
    data,
    fetchNextPage,
    isError,
    isFetching,
    isLoading,
    isRefetching,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "table-data",
      roleId,
      userId,
      departmentId,
      activeTab,
      activeDSTab,
      selectedReconUnit,
      queuesData,
      selectedResolveQueue,
    ],
    queryFn: ({ pageParam = 0 }) =>
      getResolveData({
        ...{
          selectedReconUnit,
          selectedResolveQueue,
          activeTab,
          activeDSTab,
          queueId: queuesData.filter(
            (queue) => queue.queueName === activeTabName
          )[0]?.queueId,
          roleId,
          userId,
          departmentId,
          pageParam,
        },
      }),
    enabled:
      // totalRowCount !== 0 &&
      apiFlagRef.current.canFetchData &&
      roleId !== undefined &&
      userId !== undefined &&
      departmentId !== undefined &&
      activeTab !== undefined &&
      activeDSTab !== undefined &&
      selectedReconUnit !== undefined &&
      queuesData.filter((queue) => queue.queueName === activeTabName)[0]
        ?.queueId !== undefined,
    getNextPageParam: (_lastGroup, pages) => {
      pageDetailsRef.current.page = pages.length - 1;
      return pages.length;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  let reconColumns = [];
  let rd = [];
  let tc = [];

  const allData = useMemo(() => {
    data &&
      data.pages[0]?.headerNames?.forEach((item) => {
        reconColumns.push({
          id: item.columnName,
          name: item.columnName,
          label: item.columnValue,
          value: item.columnValue,
          dataType: item.dataType,
          accessorKey: item.columnName,
          header: item.columnValue.toUpperCase(),
        });
      });
    data &&
      data.pages[0].txnDataDTO?.forEach((data) => {
        rd.push(data);
      });

    const reconStatusIndex = reconColumns.findIndex(
      (item) => item.id === "reconStatusId"
    );
    // if (reconStatusIndex !== -1) {
    //   const column = JSON.parse(JSON.stringify(reconColumns[reconStatusIndex]));
    //   column.header = "RECON STATUS";
    //   column.accessorKey = "r_s";
    //   column.name = "r_s";
    //   column.id = "r_s";
    //   reconColumns.unshift(column);
    // }

    let reconColData = reconColumns; // reconStatusHandler(reconColumns);
    reconColData.forEach((item) => {
      item.options = {
        sort: true,
      };
    });
    const payload = {
      globalReconColumns: reconColData,
    };
    columnRef.current.columns = reconColData;
    // ReconXContextData.saveReconProcessesData(payload);
    return { headerNames: reconColData, txnDataDTO: rd };
  }, [data]);

  const totalDBRowCount = dataCounts[activeDSTab + 1]
    ? dataCounts[activeDSTab + 1][selectedQueueId]
    : 0;
  tc = allData?.headerNames || [];

  let flatData = useMemo(
    () => (data ? data?.pages.flatMap((page) => page?.txnDataDTO) ?? [] : []),
    [data]
  );

  flatData = flatData.map((fd) => ({
    ...fd,
    r_s: cellHandler(fd.reconStatusId),
  }));

  let totalFetched = flatData.length;
  pageDetailsRef.current.fetchedRecords = totalFetched;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 40 &&
          !isFetching &&
          !isRefetching &&
          totalFetched < totalDBRowCount
        ) {
          debounce(fetchNextPage, 1000);
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  //scroll to top of table when sorting or filters change
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter]);

  //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    if (hasNextPage) {
      fetchMoreOnBottomReached(tableContainerRef.current);
    }
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    const queueId = queuesData.filter(
      (queue) => queue.queueName === activeTabName
    )[0]?.queueId;

    if (
      roleId &&
      selectedResolveQueue !== 0 &&
      activeTabName &&
      userId &&
      departmentId &&
      selectedReconUnit &&
      apiFlagRef.current.canFetchData &&
      activeDSTab !== -1
    ) {
      fetchQueueActions(
        roleId,
        selectedResolveQueue,
        queueId,
        callbackFetchResolveXActions
      );
    }
  }, [
    roleId,
    selectedResolveQueue,
    activeTabName,
    userId,
    departmentId,
    selectedReconUnit,
    apiFlagRef.current.canFetchData,
    activeDSTab,
  ]);

  const handleReconUnitChange = (e) => {
    setActiveTabName(""); // tweak 18-06-24
    setSelectedResolveQueue(0); // tweak 18-06-24
    setSelectedQueueId("");
    const reconUnitSelected = Number(e.target.value);
    setSelectedReconUnit(reconUnitSelected);
    UserAuthContext.setReconUnitId(reconUnitSelected);
    const payload = dropDownData.filter(
      (dd) => dd.value === +e.target.value
    )[0];
    setResolveData({
      ...resolveData,
      selectedOpsProcessData: payload,
    });
    setActiveDSTabName(payload.dataSrcConfigId1);
    setActiveDSTab(0);
  };

  const callbackFetchReconUnitData = (
    userId,
    assignedReconUnitData,
    reconUnitDropDownData,
    apiStatus
  ) => {
    if (apiStatus) {
      //calling the loadTrendsData once the reconunits are fetched. Hence placed in callback method
      globalDropDownData = reconUnitDropDownData;
      const finalReconUnitDropDownData = reconUnitDropDownData.filter(
        (data) => data.workflowEnabled
      );
      const record = finalReconUnitDropDownData.filter(
        (data) => data.reconUnitId === UserAuthContext.state.reconUnitId
      );
      if (record && record?.length > 0) {
        setResolveData({ ...resolveData, selectedOpsProcessData: record[0] });
        setActiveDSTabName(record[0].dataSrcConfigId1);
        setActiveDSTab(0);
      } else {
        setResolveData({
          ...resolveData,
          selectedOpsProcessData: finalReconUnitDropDownData[0],
        });
        setActiveDSTabName(finalReconUnitDropDownData[0]?.dataSrcConfigId1);
        setActiveDSTab(0);
      }
      setDropDownData(finalReconUnitDropDownData);
      const preSelectedVal = finalReconUnitDropDownData.filter(
        (op) => op.clientReconName === ops
      )[0]?.reconUnitId;

      setSelectedReconUnit(
        preSelectedVal
          ? preSelectedVal
          : record
          ? record[0]?.reconUnitId
          : finalReconUnitDropDownData[0]?.reconUnitId
      );
    } else {
      showFailureNotification(API_RECON_PROCESS_FAILED, callbackNotification);
    }
  };

  /**To get the dropdown recon data */
  useEffect(() => {
    fetchReconUnitsData(
      UserAuthContext.state?.userId,
      callbackFetchReconUnitData
    );
  }, [UserAuthContext.state?.userId]);

  /**To get list of queues */
  useEffect(() => {
    if (UserAuthContext.state?.userId !== 0 && selectedResolveQueue !== 0) {
      loadResolveQueues(UserAuthContext.state.roleId, selectedResolveQueue);
    }
  }, [UserAuthContext.state?.userId, selectedResolveQueue]);

  useEffect(() => {
    if (isResolveWorkSpaceLoading) {
      let { userId, clientOrgId, roleId, departmentName, loginName } =
        UserAuthContext.state;
      let orgId = clientOrgId;
      userDepartmentName = departmentName;
      if (userId === undefined || userId === 0) {
        //this means refresh or session of browser got refreshed. So lets fetch the user details from Database
        /// for doing lets get the loginName from sessionStorage see if it is available, try fetching the details

        if (loginName !== undefined || loginName !== "") {
          //this means user had logged in earlier try fetching from sessionStorage loginName
          fetchUserDetailsByLogin(loginName, callBackFetchUserDetailsByLogin);
          showAlertNotification(
            "Please double click the records for working on individual items",
            callbackNotification
          );
        }
      }
    }
    return () => {
      console.log(
        "Component ResolveXXWorkSpace unloading cleanup to be done here!!!"
      );
    };
  }, []);

  useEffect(() => {
    setTableOptions((prevOptions) => ({
      ...prevOptions,
      textLabels: {
        body: {
          noMatch: isResolveXLoading ? <Loader /> : "No Data Found!!!",
        },
      },
    }));
  }, [isResolveXLoading]);

  const workflowCallback = (data) => {
    let payload = [];
    if (data.length > 0) {
      data.forEach((item) => {
        payload.push({
          value: item.flowDefnKeyName,
          label: item.flowDefnName,
          isdisabled: false,
        });
      });
      callbackFetchResolveQueuesData(payload, true);
    }
  };

  useEffect(() => {
    if (selectedReconUnit && selectedReconUnit !== 0) {
      fetchmappedFlowUnits(selectedReconUnit, workflowCallback);
    }
  }, [selectedReconUnit]);

  const callbackFetchResolveXActions = (
    fetchedResolveActionsConfig,
    apiStatus
  ) => {
    if (apiStatus) {
      setResolveActionsConfigList(fetchedResolveActionsConfig);
    } else {
      showFailureNotification(
        API_FETCH_RESOLVE_ACTIONS_CONFIG_FAILED,
        callbackNotification
      );
    }
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    const { userId, clientOrgId, roleId, departmentName } = userDetailsState;
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
      userDepartmentName = departmentName;
    } else {
      //failure could be due to token expiry, raise an alert saying Token Expired and route to login page
      showAlertNotification(USER_SESSION_TIME_OUT, callbackNotification);
      UserAuthContext.clearUserDetailsAuthState();
      window.location.assign("/");
    }
  };

  const handleChangePage = (pageNum) => {
    /**
     * Note this method is callback provided by MuiTable, as of now it looks like it is not allowing to refer
     * the state variables in functional component. However it is given that class component it works. Hence I
     * added globalReconUnit and globalReconStatus which are more like additional variables which can be referred
     * it is our responsibility to update this variables whenever state object is getting modified
     */

    options.page = pageNum;
    setTableOptions(options);
    currentPage = pageNum;
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const handleRowDoubleClick = (rowData) => {
    setResolveWorkSpaceLoading(true);
    setResolveQueueOptionSelectable(true);
    globalFlowIdSelected = rowData._valuesCache?.rawDataId;
    const rd = rowData._valuesCache;
    fetchFormData(
      rd?.rawDataId,
      rd?.reconUnitId,
      selectedResolveQueue,
      callbackLoadFormData
    );
    const queueId = WorkflowDataContext.state.queues.filter(
      (queue) => queue.queueName === activeTabName
    )[0]?.queueId;
    getResolveWorkItemData(
      roleId,
      globalResolveQueueSelected,
      queueId,
      userId,
      departmentId,
      rd?.reconUnitId,
      rd?.rawDataId,
      (res) => {
        setResolveWorkItemData(res);
      }
    );
  };

  const callbackFetchLoadAuditData = (
    resolveId,
    resolveQueue,
    auditData,
    apiStatus
  ) => {
    if (apiStatus) {
      const columns = transformColumnName(auditData);
      setTableAuditColumns(columns);
      setTableAuditData(auditData);
      setShowFormObject(apiStatus);
    } else {
      showAlertNotification(API_AUDITINFO_FAILED, callbackNotification);
    }
  };
  const setResolveQueueOptionSelectable = (selectOption) => {
    let lDropDownData = [];

    //since this is triggered from Table mui which is not able to refer state objects referring the globaldropdown object
    globalResolveQueueData.map((selectObj, index) => {
      selectObj.isdisabled = selectOption;
      lDropDownData.push(selectObj);
    });

    globalResolveQueueData = lDropDownData;
    setResolveQueueList(lDropDownData);
  };

  const handleBackToReSolveXSpace = (status) => {
    setShowFormObject(false);
    setResolveQueueOptionSelectable(false); //setting the dropdown selects to selectable
    globalFlowIdSelected = undefined;
  };

  const callbackLoadFormData = (
    resolveId,
    reconUnitId,
    reconUnitName,
    formObject,
    apiStatus
  ) => {
    if (apiStatus) {
      let newFormDataObj = { ...rowDetailFormObject };
      newFormDataObj = formObject;

      setRowDetailFormObject(newFormDataObj);
      fetchLoadAuditData(
        globalFlowIdSelected,
        globalResolveQueueSelected,
        callbackFetchLoadAuditData
      );
      setShowFormObject(apiStatus);
    } else {
      setShowFormObject(apiStatus);
      showFailureNotification(API_LOADFORM_FAILED, callbackNotification);
    }
    setResolveWorkSpaceLoading(false);
  };

  const fetchResolveCounts = async (queuesData) => {
    for (let i = 0; i < queuesData.length; i++) {
      const { queueId } = queuesData[i];
      const { reconEnabled, workflowEnabled } =
        resolveData.selectedOpsProcessData;
      const limit = reconEnabled && workflowEnabled ? 3 : 2;
      // for data sources 1 and 2
      for (let j = 1; j < limit; j++) {
        let response;
        response = await axios.post(
          `${API}/reconquery/fetchResolveCount?reconUnitId=${selectedReconUnit}&dataSrcConfigId=${j}&wfProcessId=${selectedResolveQueue}&queueId=${queueId}&roleId=${roleId}&departmentId=${departmentId}&loggedInUser=${userId}`,
          { filterColumnsDTOList: [] }
        );
        if (response.status === 200) {
          setDataCounts((prevState) => ({
            ...prevState,
            [j]: {
              ...prevState[j],
              [queueId]: response.data?.totalRows || 0,
            },
          }));
        }
      }
    }
  };

  useEffect(() => {
    if (queuesData.length > 0 && resolveData.selectedOpsProcessData !== null) {
      setDataCounts({});
      fetchResolveCounts(queuesData);
    }
  }, [queuesData]);

  const loadResolveQueues = (roleId, wfProcessId) => {
    fetchWorkflowQueues(roleId, wfProcessId, (data = []) => {
      apiFlagRef.current.canFetchData = true;
      if (data.length > 0) {
        const { queueName, queueId } = data[0];
        setActiveTabName(queueName);
        setSelectedQueueId(queueId);
      }
      setActiveTab(0);
      WorkflowDataContext.saveQueues(data);
    });
  };

  const callbackFetchResolveQueuesData = (
    fetchedResolveXDropDownData,
    apiStatus
  ) => {
    if (apiStatus) {
      const selectedVal = fetchedResolveXDropDownData.filter(
        (wfp) => wfp.label === wf
      )[0]?.value;
      setSelectedResolveQueue(
        selectedVal || fetchedResolveXDropDownData[0]?.value
      );

      setResolveQueueList(fetchedResolveXDropDownData);
      globalResolveQueueData = fetchedResolveXDropDownData;

      /* Assing first dropdown list value if state is not avaialble on initial render */
      let resolveQueueSelected =
        selectedResolveQueue === 0
          ? fetchedResolveXDropDownData[0].value
          : selectedResolveQueue;
      globalResolveQueueSelected = resolveQueueSelected;

      /* Update Heading label based on selected dropdown id*/
      let selectedResolveUnitObj = [];
      if (UserAuthContext.state.resolveUnitId) {
        selectedResolveUnitObj = fetchedResolveXDropDownData.filter(
          (el) => el.value == selectedResolveQueue
        );
      }

      let queueName =
        (selectedResolveUnitObj.length > 0 &&
          selectedResolveUnitObj[0].label) ||
        fetchedResolveXDropDownData[0].label;
      globalResolveQueueName = queueName;
      setSelectedQueueName(queueName);
      setResolveWorkSpaceLoading(false); //tweak on 06-10-24
    } else {
      showFailureNotification(API_RESOLVEX_QUEUES_FAILED, callbackNotification);
      setResolveWorkSpaceLoading(false);
    }
    setTableOptions((prevOptions) => ({
      ...prevOptions,
      textLabels: {
        body: {
          noMatch: isResolveXLoading ? <Loader /> : "No Data Found!!!",
        },
      },
    }));
  };

  const callbackfetchResolveRecords = (resolveData, apiStatus) => {
    setTableData(resolveData);
    rowRef.current.rowData = resolveData;
    setResolveWorkSpaceLoading(false);
    setResolveQueueOptionSelectable(false); //enabling the select records
    const columns = transformColumnName(resolveData);
    setTableColumns(columns);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleResolveQueueChange = (e) => {
    apiFlagRef.current.canFetchData = false;
    let resolveQueueSelected = e.target.value;
    setTableData([]); // tweak 18-06-24 to fix the data loading
    setSelectedResolveQueue(resolveQueueSelected);
    UserAuthContext.setResolveUnitId(resolveQueueSelected);
    let lDropDownData = [];
    let queueName = "";

    resolveQueueList.forEach((selectObj, index) => {
      if (isResolveWorkSpaceLoading || showFormObject) {
        // if the resolve workspace loading or form object is opened we donot allow for select modification
        selectObj.isdisabled = true;
      } else {
        selectObj.isdisabled = false;
      }

      if (selectObj.value === resolveQueueSelected) {
        setSelectedQueueName(selectObj.label);
        queueName = selectObj.label;

        //ideally we should break here but donot know how to do in react JS leaving it for now :()
      }
      lDropDownData.push(selectObj);
    });

    globalResolveQueueName = queueName;

    globalResolveQueueSelected = resolveQueueSelected;
  };

  const handleCloseResolveActions = () => {
    setOpenResolveActions(null);
  };

  const getQueueData = () => {
    setTimeout(() => {
      apiFlagRef.current.canFetchData = true;
      handleBackToReSolveXSpace();
    }, 10);
  };

  const doActionCallback = (data, apiStatus) => {
    if (apiStatus === true) {
      setDataCounts({});
      fetchResolveCounts(queuesData);
      closeOverlay();
      apiFlagRef.current.canFetchData = false;
      showSuccessNotification(data.responseMessage, callbackNotification);
      getQueueData();
    } else {
      showAlertNotification(data.responseMessage, callbackNotification);
    }
  };

  const doAction = () => {
    if (userNotes.trim() === "") {
      showAlertNotification("Please fill User Notes", callbackNotification);
      return;
    }
    const queueData = WorkflowDataContext.state.queues.filter(
      (queue) => queue.queueName === activeTabName
    )[0];
    const queueActionDTO = {
      wfProcessId: globalResolveQueueSelected,
      queueId: queueData?.queueId,
      actionId: ref.current.actionItem,
      loggedInUserId: userId,
      initiatedLoginName: loginName,
      roleId: roleId,
      userNotes: userNotes,
    };

    const payload = {
      resolveBaseData: resolveWorkItemData,
      queueActionDTO,
    };
    triggerDoAction(payload, doActionCallback);
    setOpenResolveActions(null);
    ref.current.actionItem = "";
  };
  const handleMenuItemClick = (actionId, e) => {
    ref.current.actionItem = actionId;
    setOverlay(true);
  };

  const handleClickResolveActions = (event) => {
    if (openResolveActions && openResolveActions.contains(event.target)) {
      setOpenResolveActions(null);
    } else {
      setOpenResolveActions(event.currentTarget);
    }
  };

  const handleCloseFollowupDialog = () => {
    setFollowupDialogOpen(false);
  };

  const handleAddressChange = (e) => {
    //setToAddress(e.target.value);
    emailFormToAddress = e.target.value;
  };

  const handleSubjectChange = (e) => {
    emailFormSubject = e.target.value;
  };

  const handleMessageChange = (e) => {
    emailMessage = e.target.value;
  };

  const executeFollowupAction = (e) => {
    e.preventDefault();
    setEmailLoader(true);
    const { userId, loginName } = UserAuthContext.state;
    let emailFollowUpObj = {
      emailObject: {
        emailTextBody: emailMessage,
        subject: emailFormSubject,
        to: emailFormToAddress,
      },
      flowDefnKeyName: globalResolveQueueSelected,
      flowItemToFollowup: rowDetailFormObject.txnDataDTO[0]?.rawDataId, //globalFlowIdSelected,
      userId: userId,
      userName: loginName,
    };
    triggerFollowupAction(emailFollowUpObj, callbackTriggerFollowupAction);
  };

  const callbackTriggerFollowupAction = (apiStatus) => {
    if (apiStatus) {
      showSuccessNotification(
        "Followup email sent successfully!",
        callbackNotification
      );
      setFollowupDialogOpen(false);
      setEmailLoader(false);
    } else {
      showFailureNotification(
        "Unable to trigger email API for followup!!!",
        callbackNotification
      );
    }
  };

  const followUpAction = () => {
    setFollowupDialogOpen(true);
  };

  const handleAddForm = () => {
    setModalOpen(true);
    apiFlagRef.current.canFetchData = false;
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleFormSuccess = () => {
    apiFlagRef.current.canFetchData = true;
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ margin: "0 -20px" }}>
        <Card plain>
          <GridContainer
            spacing={2}
            sx={{ width: { xs: "100%" } }}
            style={{
              marginBottom: "20px",
              alignItems: "center",
              "& > div": {
                paddingLeft: "0",
              },
            }}
          >
            <GridItem
              sx={{
                paddingLeft: {
                  xs: "0px !important",
                  sm: "0px !important",
                  md: "0px !important",
                },
              }}
              xs={12}
              sm={4}
              md={3}
              lg={2}
              disableSpacing={true}
            >
              <SayaCombo
                optionsData={dropDownData}
                value={selectedReconUnit}
                defaultValue={selectedReconUnit}
                labelText="Ops Processes"
                groupText="Selected Recon Process"
                name="selectedReconUnit"
                handleChange={handleReconUnitChange}
                className="Select"
                height="20px"
                headerCombo
                inputProps={
                  showFormObject
                    ? {
                        readOnly: true,
                        style: {
                          opacity: "0.85",
                          pointerEvents: "none",
                        },
                      }
                    : {}
                }
              />
            </GridItem>

            <GridItem
              xs={12}
              sm={4}
              md={3}
              lg={2}
              disableSpacing={true}
              sx={{
                paddingLeft: {
                  xs: "0px !important",
                  sm: "0px !important",
                  md: "22px !important",
                },
              }}
            >
              {resolveQueueList && resolveQueueList.length > 0 && (
                <SayaCombo
                  optionsData={resolveQueueList}
                  value={selectedResolveQueue}
                  defaultValue={selectedResolveQueue}
                  labelText="Workflow"
                  groupText="Selected Queue"
                  handleChange={handleResolveQueueChange}
                  className="Select"
                  height="20px"
                  headerCombo
                  inputProps={
                    showFormObject
                      ? {
                          readOnly: true,
                          style: {
                            opacity: "0.85",
                            pointerEvents: "none",
                          },
                        }
                      : {}
                  }
                />
              )}
            </GridItem>

            <GridItem
              xs={6}
              sm={3}
              md={2}
              disableSpacing={true}
              sx={{
                paddingTop: "22px !important",
                paddingLeft: { xs: "0 !important", sm: "30px !important" },
              }}
            >
              {!showFormObject && (
                <Button
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  onClick={() => handleAddForm()}
                >
                  <AddBoxOutlinedIcon key="add" />
                </Button>
              )}
            </GridItem>

            <GridItem xs={1} sm={4} md={1} disableSpacing={true}></GridItem>
            {!modalOpen && (
              <GridItem
                xs={5}
                sm={5}
                md={5}
                disableSpacing={true}
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <CheckAccess {...props} action={"workflow actions"}>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    className={classes.actionbtn}
                  >
                    <div className={classes.manager + " shade-wrapper"}>
                      <Tooltip title="ResolveX Actions" placement="top">
                        <List
                          className={classes.icons}
                          onClick={handleClickResolveActions}
                          style={{ cursor: "pointer" }}
                          fontSize="large"
                        />
                      </Tooltip>

                      <Popper
                        open={Boolean(openResolveActions)}
                        anchorEl={openResolveActions}
                        transition
                        disablePortal={false}
                        className={
                          classNames({
                            [classes.popperClose]: !openResolveActions,
                          }) +
                          " " +
                          classes.popperNav
                        }
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener
                                onClickAway={handleCloseResolveActions}
                              >
                                <MenuList role="menu">
                                  {resolveActionsConfigList.map(
                                    (resolvexActionsConfigData, index) => (
                                      <MenuItem
                                        disabled={!showFormObject}
                                        onClick={(e) =>
                                          handleMenuItemClick(
                                            resolvexActionsConfigData.actionId,
                                            e
                                          )
                                        }
                                        className={classes.dropdownItem}
                                      >
                                        {resolvexActionsConfigData.actionName}
                                      </MenuItem>
                                    )
                                  )}
                                  {showFormObject === true && (
                                    <MenuItem
                                      onClick={followUpAction}
                                      className={classes.dropdownItem}
                                    >
                                      Followup
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </div>
                    {/* componentize this */}
                    {showFormObject === true ? (
                      <Tooltip title="Back to ResolveX Workspace">
                        <KeyboardBackspace
                          className={classes.exitSvgIcon}
                          style={{ cursor: "pointer" }}
                          fontSize="large"
                          onClick={handleBackToReSolveXSpace}
                        />
                      </Tooltip>
                    ) : null}
                  </Box>
                </CheckAccess>
              </GridItem>
            )}
          </GridContainer>
          {modalOpen ? (
            <AddForm
              modalOpenState={modalOpen}
              modalCloseHandler={handleModalClose}
              reconUnitId={selectedReconUnit}
              workflowId={selectedResolveQueue}
              loggedUserId={userId}
              userLoginName={loginName}
              userRoleId={roleId}
              successHandler={handleFormSuccess}
            />
          ) : (
            <div className={classes.rWrapper}>
              <GridContainer
                style={{
                  zIndex: "1",
                  top: "3px",
                  position: "relative",
                  marginLeft: "-15px",
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="start"
                    //className={classes.switchInput}
                  >
                    <ResolveXQueues
                      queuesData={queuesData}
                      handleTabChange={handleTabChange}
                      changeTab={changeTab}
                      activeTabName={activeTabName}
                      activeTab={activeTab}
                      totalRowCount={dataCounts}
                    />
                  </Box>
                </GridItem>
              </GridContainer>

              <CardBody style={{ paddingTop: 0, minHeight: 450 }}>
                {showFormObject === true ? (
                  <div className={classes.dynamicForm}>
                    <DynamicFormClass dataObj={rowDetailFormObject} />
                    <SAYATable
                      title={
                        "Audit information for resolve ID - " +
                        globalFlowIdSelected
                      }
                      data={tableAuditData}
                      columns={tableAuditColumns}
                      options={auditTableOptions}
                    />
                  </div>
                ) : (
                  <Box
                    className={reconClasses.reconTableWrapper}
                    sx={{
                      width: "100%",
                      borderBottom: 0,
                      borderColor: "divider",
                    }}
                  >
                    {resolveData.selectedOpsProcessData !== null && (
                      <MetadataTabs
                        tabsData={resolveData.selectedOpsProcessData}
                        handleDSTabChange={handleDSTabChange}
                        changeTab={changeDSTab}
                        // activeDSTabName={activeDSTabName}
                        activeTab={activeDSTab}
                        selectedQueueId={selectedQueueId}
                        totalRowCount={dataCounts}
                      />
                    )}
                    <Typography
                      className={classes.reconTitle}
                      sx={{
                        margin: 0,
                        position: "absolute",
                        marginTop: "10px",
                        zIndex: "9",
                        left: "18px",
                        fontWeight: "400",
                      }}
                    >
                      <span> RESOLVEX WORKSPACE - </span>
                      {globalResolveQueueName}
                    </Typography>
                    <MaterialReactTable
                      columns={tc}
                      data={flatData}
                      enablePagination={false}
                      manualPagination
                      enableDensityToggle={false}
                      enableFullScreenToggle={false}
                      // initialState={{
                      //   density: "compact",
                      //   columnPinning: { left: ["reconStatusId"] },
                      // }}
                      enableGlobalFilterModes
                      enableColumnActions={false}
                      enableBottomToolbar={true}
                      enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                      muiTableContainerProps={{
                        ref: tableContainerRef, //get access to the table container element
                        sx: { maxHeight: "600px" }, //give the table a max height
                        onScroll: (
                          event //add an event listener to the table container element
                        ) => fetchMoreOnBottomReached(event.target),
                      }}
                      onColumnFiltersChange={setColumnFilters}
                      onGlobalFilterChange={setGlobalFilter}
                      onSortingChange={setSorting}
                      renderBottomToolbarCustomActions={() => (
                        <Typography>
                          Fetched {totalFetched} of {totalDBRowCount} total
                          rows.
                        </Typography>
                      )}
                      state={{
                        columnFilters,
                        globalFilter,
                        sorting,
                      }}
                      rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
                      rowVirtualizerProps={{ overscan: 4 }}
                      muiTableBodyRowProps={({ row }) => ({
                        onDoubleClick: (event) => {
                          if (
                            checkAccessHandler(
                              "double click records",
                              allowedPermissions
                            )
                          ) {
                            handleRowDoubleClick(row);
                          }
                        },
                        sx: {
                          backgroundColor: "#fff",
                          cursor: "pointer", //you might want to change the cursor too when adding an onClick
                        },
                      })}
                      muiLinearProgressProps={({ isTopToolbar }) => ({
                        sx: {
                          display: isTopToolbar ? "block" : "none", //hide bottom progress bar
                        },
                      })}
                      // rowCount={tableData.length}
                      muiTableBodyCellProps={({
                        cell,
                        column,
                        row,
                        table,
                      }) => ({
                        className: "mui-cell",
                        title: cell.getValue(),
                      })}
                      renderEmptyRowsFallback={() => (
                        <Typography
                          sx={{
                            textAlign: "center",
                            position: "absolute",
                            left: "45%",
                            top: "35%",
                          }}
                        >
                          No Records to display
                        </Typography>
                      )}
                    />
                  </Box>
                )}
              </CardBody>
            </div>
          )}
        </Card>
      </GridItem>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
      <SayaEmailDialog
        openFollowUpDialog={isFollowupDialogOpen}
        handleCloseFollowupDialog={handleCloseFollowupDialog}
        dialogTitle={"Follow Up Email"}
        handleAddressChange={handleAddressChange}
        handleSubjectChange={handleSubjectChange}
        handleMessageChange={handleMessageChange}
        followupFlowItem={executeFollowupAction}
        toAddress={toAddress}
        subject={emailSubject}
        message={message}
        loading={emailLoader}
      ></SayaEmailDialog>
      {showOverlay && (
        <Overlay
          {...{
            isOverlayOpen: showOverlay,
            closeOverlay,
            title: "User Notes",
            actions: true,
            handler: doAction,
          }}
        >
          <FormControl fullWidth sx={{ m: 1, ml: 0 }} variant="filled">
            <TextareaAutosize
              style={{ fontFamily: "inherit", border: "1px solid #ccc" }}
              required
              id="outlined-required"
              label="User Notes"
              variant="outlined"
              size="large"
              name="userNotes"
              value={userNotes}
              minRows={5}
              maxLength={500}
              onChange={(e) => setUserNotes(e.target.value)}
            />
          </FormControl>
        </Overlay>
      )}
    </GridContainer>
  );
}
