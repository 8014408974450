"use client";

import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Check, X } from "lucide-react";

const CoolSelect = ({
  label,
  name,
  value = [],
  placeholder = "Please select",
  isRequired,
  selectData = [],
  optionValue,
  optionLabel,
  onChange,
  isMulti = false,
  errorMessage = "",
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = selectData.filter((item) =>
    item[optionLabel]?.toLowerCase()?.includes(searchTerm.toLowerCase())
  );

  const handleSelect = (selectedValue) => {
    if (isMulti) {
      const updatedValues = value.includes(selectedValue)
        ? value.filter((v) => v !== selectedValue)
        : [...value, selectedValue];
      onChange(updatedValues);
    } else {
      onChange(selectedValue);
      setIsOpen(false);
    }
  };

  const renderValue = () => {
    if (isMulti) {
      return value.length === selectData.length
        ? "All selected"
        : value.length > 0
        ? `${value.length} selected`
        : placeholder;
    } else {
      const selectedItem = selectData.find(
        (item) => item[optionValue] === value
      );
      return selectedItem ? selectedItem[optionLabel] : placeholder;
    }
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label} {isRequired && <span className="text-red-500">*</span>}
      </label>
      <div
        className="bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 cursor-pointer flex items-center justify-between hover:border-pink-500 transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate">{renderValue()}</span>
        <ChevronDown
          className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
        />
      </div>
      <div className="text-red-500 text-xs pt-1">{errorMessage}</div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          <div className="sticky top-0 bg-white p-2">
            <input
              type="text"
              className="w-full px-3 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {filteredOptions.map((item, index) => (
            <div
              key={`${item[optionValue]}-${index}`}
              className={`px-4 py-2 cursor-pointer hover:bg-slate-50 flex items-center justify-between ${
                isMulti && value.includes(item[optionValue])
                  ? "bg-slate-100"
                  : ""
              }`}
              onClick={() => handleSelect(item[optionValue])}
            >
              <span className='text-sm'>{item[optionLabel]}</span>
              {isMulti && value.includes(item[optionValue]) && (
                <Check className="w-4 h-4 text-pink-600" />
              )}
            </div>
          ))}
        </div>
      )}
      {/* {isMulti && value.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-2">
          {value.map((val) => {
            const item = selectData.find((i) => i[optionValue] === val)
            return (
              <span
                key={val}
                className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full flex items-center"
              >
                {item ? item[optionLabel] : val}
                <X
                  className="w-4 h-4 ml-1 cursor-pointer"
                  onClick={() => handleSelect(val)}
                />
              </span>
            )
          })}
        </div>
      )} */}
    </div>
  );
};

export default React.memo(CoolSelect);
