'use client'

import React, { useMemo, useCallback, useState, useRef } from "react"
import { Doughnut } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Title } from "chart.js"

ChartJS.register(ArcElement, Tooltip, Title)

export default function PieChart({
  chartData = [],
  label = "",
  value = "",
  title,
  sortKey,
  enableSorting = false,
}) {
  const [selectedSorting, setSelectedSorting] = useState("Descending")
  const chartRef = useRef(null)

  const generateColors = useCallback((count) => {
    const baseColors = [
      "rgb(255, 99, 132)", // Red-Orange
      "rgb(255, 159, 64)", // Orange
      "rgb(255, 205, 86)", // Yellow
      "rgb(75, 192, 192)", // Green
      "rgb(54, 162, 235)", // Blue
      "rgb(153, 102, 255)", // Purple
      "rgb(255, 99, 71)", // Pink
    ]

    const mixColors = (color1, color2, weight) => {
      const w1 = weight
      const w2 = 1 - w1
      const rgb1 = color1.match(/\d+/g).map(Number)
      const rgb2 = color2.match(/\d+/g).map(Number)
      const mixed = rgb1.map((c, i) => Math.round(c * w1 + rgb2[i] * w2))
      return `rgb(${mixed.join(",")})`
    }

    return Array.from({ length: count }, (_, i) => {
      let color
      if (i < baseColors.length) {
        color = baseColors[i]
      } else {
        const index1 = i % baseColors.length
        const index2 = (i + 1) % baseColors.length
        const weight = i % 2 === 0 ? 0.7 : 0.3
        color = mixColors(baseColors[index1], baseColors[index2], weight)
      }

      return color.replace("rgb", "rgba").replace(")", ", 0.5)")
    })
  }, [])

  const sortedData = useMemo(() => {
    if (!chartData || !enableSorting) return chartData
    return [...chartData].sort((a, b) => {
      if (selectedSorting === "Ascending") {
        return a[sortKey] - b[sortKey]
      } else {
        return b[sortKey] - a[sortKey]
      }
    })
  }, [chartData, sortKey, selectedSorting, enableSorting])

  const backgroundColors = useMemo(
    () => generateColors(chartData.length),
    [chartData.length, generateColors]
  )

  const data = useMemo(() => {
    const labels = sortedData.map((item) => item[label])
    const dataValues = sortedData.map((item) => item[value])

    return {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: sortedData.map(
            (item) => backgroundColors[chartData.indexOf(item)]
          ),
          borderColor: sortedData.map((item) =>
            backgroundColors[chartData.indexOf(item)].replace("0.7", "1")
          ),
          borderWidth: 1,
        },
      ],
    }
  }, [sortedData, label, value, backgroundColors, chartData])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "65%",
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        titleFont: {
          family: "Poppins",
        },
        bodyFont: {
          family: "Poppins",
        },
      },
      gridlines: {
        id: "gridlines",
        beforeDraw: (chart) => {
          const { ctx, chartArea } = chart
          const { top, bottom, left, right } = chartArea
          const centerX = (left + right) / 2
          const centerY = (top + bottom) / 2
          const radius = Math.min(right - left, bottom - top) / 2

          ctx.save()
          ctx.beginPath()
          ctx.strokeStyle = "rgba(0, 0, 0, 0.1)"
          ctx.lineWidth = 1


          for (let i = 1; i <= 3; i++) {
            ctx.beginPath()
            ctx.arc(centerX, centerY, (radius / 4) * i, 0, 2 * Math.PI)
            ctx.stroke()
          }

          ctx.restore()
        },
      },
    },
  }

  const CustomLegend = ({ data }) => (
    <div className="h-[120px] w-full overflow-y-auto relative z-[9] top-4">
      <div className="flex flex-wrap gap-2 p-2 justify-center">
        {data.labels.map((label, index) => (
          <div key={index} className="flex items-center">
            <div
              className="w-2.5 h-2.5 mr-2 rounded-lg"
              style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
            />
            <span className="text-xs">{label}</span>
          </div>
        ))}
      </div>
    </div>
  )

  if (chartData.length === 0) {
    return (
      <div className="w-full h-[400px] bg-white rounded-lg shadow-md flex items-center justify-center">
        <p className="text-gray-500 text-sm">
          No data available for the selected criteria.
        </p>
      </div>
    )
  }

  return (
    <div className="w-full h-[420px] bg-white rounded-lg shadow-md overflow-hidden">
      <div className="px-6 py-3.5 border-b">
        <h2 className="leading-4 sm:leading-normal text-sm font-medium text-gray-800">{title}</h2>
      </div>
      <div className="h-[calc(100%-4rem)] p-4 relative">
        <div className="absolute inset-0 z-0 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:4rem_4rem]" />
        <div className="relative z-[1] h-[220px]">
          <Doughnut data={data} options={options} ref={chartRef} />
        </div>
        <CustomLegend data={data} />
      </div>
    </div>
  )
}