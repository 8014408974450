import { Typography } from "@mui/material";

export const showInfo = (text = "") => {
  return <Typography>{text}</Typography>;
};

export const sortData = (data = [], key, mode = "DE") => {
  if (key) {
    if (mode === "AS") {
      return data.sort((a, b) => a[key] - b[key]);
    }
    return data.sort((a, b) => b[key] - a[key]);
  }
  return data;
};
