import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Chip, Box, Typography, Modal, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { DATAX } from "URL";

// Define styles using makeStyles
const useStyles = makeStyles({
  tableContainer: {
    boxShadow: "none",
    borderRadius: "0px",
    border: "1px solid #f5f5f5",
  },
  headerCell: {
    padding: "8px 16px",
    fontWeight: "bold",
    fontSize: "14px",
    backgroundColor: "#f5f5f5",
  },
  bodyCell: {
    padding: "3px 10px",
    fontSize: "14px",
  },
  chipSuccess: {
    backgroundColor: "#ebf8e8",
    color: "#5ec615",
  },
  chipFailure: {
    backgroundColor: "#fbebeb",
    color: "#e64142",
  },
  chipPending: {
    backgroundColor: "#fdf9e7",
    color: "#edbe15",
  },
  paperRoot: {
    boxShadow: "none",
    borderRadius: "0",
    border: "1px solid #ddd",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    padding: "16px",
    boxShadow: 24,
    borderRadius: "4px",
  },
  closeIcon: {
    position: "absolute",
    top: 8,
    right: 8,
    color: "#aaa",
  },
});

// Function to capitalize and add spaces between words
const capitalize = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

// Function to get status chip with MUI styles
const getStatusChip = (status, classes) => {
  switch (status) {
    case "Failure":
      return <Chip label="Failure" className={classes.chipFailure} />;
    case "SUCCESS":
      return <Chip label="Success" className={classes.chipSuccess} />;
    case "Pending":
      return <Chip label="Pending" className={classes.chipPending} />;
    default:
      return <Chip label={status} />;
  }
};

const DataIngestionTable = ({ auditData }) => {
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const classes = useStyles();

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalData(null);
  };

  const fetchExecutionData = async (executionId) => {
    try {
      const response = await axios.get(`${DATAX}/ingestion/executions/${executionId}`);
      setModalData(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching execution data:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 100);
    return () => clearTimeout(timer);
  }, [auditData]);

  useEffect(() => {
    if (auditData && auditData.length > 0) {
      const dynamicColumns = Object.keys(auditData[0]).map((key) => {
        return {
          name: key,
          label: capitalize(key),
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              className: classes.bodyCell,
              style: key === "eventId" ? { minWidth: "400px" } : {}, // Set width for executionId column
            }),
            setCellHeaderProps: () => ({
              className: classes.headerCell,
              style: key === "executionId" ? { width: "150px" } : {}, // Set width for executionId header
            }),
            ...(key === "upload_status"
              ? {
                  customBodyRender: (value) => getStatusChip(value, classes),
                  filterType: "dropdown",
                }
              : {}),
            ...(key === "executionId"
              ? {
                  customBodyRender: (value) =>
                    value ? (
                      <Button onClick={() => fetchExecutionData(value)} color="primary">
                        {value}
                      </Button>
                    ) : (
                      <Button style={{ textAlign: 'center', fontWeight: 'normal' }} disabled>N/A</Button>
                    ),
                }
              : {}),
          },
        };
      });
      setColumns(dynamicColumns);
    }
  }, [auditData, classes]);

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10,
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: true,
    pagination: true,
    paginationOptions: {
      labelRowsPerPage: "Rows per page",
    },
    rowHover: true,
    setTableProps: () => ({
      className: classes.tableContainer,
    }),
    textLabels: {
      body: {
        noMatch: loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={{ ml: 2 }}>
              Loading data...
            </Typography>
          </Box>
        ) : (
          "No data to display"
        ),
      },
    },
  };

  return (
    <>
      <MUIDataTable
        title={"Upload History"}
        data={loading ? [] : auditData}
        columns={columns}
        options={options}
      />
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box className={classes.modal}>
          <IconButton className={classes.closeIcon} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom sx={{ fontSize: '16px !important', fontWeight: '500', borderBottom: '1px solid #ccc', marginX: '-15px', paddingBottom: '10px', textTransform: 'capitalize', paddingX: '16px' }}>
            Execution Details
          </Typography>
          {modalData ? (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '500' }}>Execution ID</TableCell>
                    <TableCell sx={{ color: '#a1a1a1' }}>{modalData.executionId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '500' }}>Status</TableCell>
                    <TableCell sx={{ color: '#a1a1a1' }}>{modalData.executionStatus}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '500' }}>Record Count</TableCell>
                    <TableCell sx={{ color: '#a1a1a1' }}>{modalData.recordCount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '500' }}>Time Taken</TableCell>
                    <TableCell sx={{ color: '#a1a1a1' }}>{modalData.timeTaken}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default DataIngestionTable;
